/**
 * Created by amine on 25/10/2016.
 */
(function () {
    'use strict';

    module.exports = mnWebSocketService;

    const wsMessage = require("./wsMessage");
    const {Subject} = require("rxjs");

    function mnWebSocketService(connection, options) {
        $get.$inject = ['$q', '$auth', '$mdToast', '$translate', '$mdDialog'];

        function $get($q, $auth, $mdToast, $translate, $mdDialog) {
            let self = this;

            let _subs = {};
            let _calls = [];
            let _notifications = [];

            let runDefer = null;
            let initiated = false;
            let checkingCallsMaximum = 5;
            let checkingCallsTimeout = 1000;

            let connectionDialog = false;
            let connectionDialogObj = $mdDialog.mnConnectionLostDialog();

            self.run = run;
            self.call = call;
            self.sub = sub;
            self.unsub = unsub;
            self.pub = pub;
            self.logout = logout;

            function run() {
                runDefer = $q.defer();

                // if (options.exposeIt) window._ws = self;

                connection.handleMessage = handle;
                options.subject.subscribe({next: authentication});
                options.connectionSubject.subscribe({next: handleConnectionStatus});

                return runDefer.promise;
            }

            function handle(msg) {
                switch (msg.type) {
                    case 2:
                        callRep(msg);
                        break;
                    case 5:
                        pubRep(msg);
                        break;
                    case 6:
                        notified(msg);
                        break;
                }
            }

            function authentication() {
                connection.authentication($auth)
                    .then(authenticated, reject);

                function authenticated(result) {
                    if (initiated) reSub();
                    initiated = result;
                    runDefer.resolve(result);
                }

                function reject(error) {
                    runDefer.reject(error);

                    if (!_.isEmpty(error)) {
                        let simpleToast = $mdToast.simple()
                            .textContent($translate['instant'](error))
                            .position("bottom left")
                            .hideDelay(5000);

                        $mdToast.show(simpleToast);
                    }

                }
            }

            function handleConnectionStatus(status) {
                if (!status && connectionDialog || status && !connectionDialog) return;
                else if (status && connectionDialog) {
                    connectionDialog = false;
                    $mdDialog.hide(connectionDialogObj, false);
                }
                else {
                    connectionDialog = true;
                    $mdDialog.show(connectionDialogObj);
                }
            }

            function call(topic, body) {
                let msg = getWsMessage(wsMessage.prototype.CALL, topic);

                msg.body = _.isUndefined(body) ? {} : body;

                let deferred = $q.defer()
                _calls[msg.id] = deferred;

                connection.sendMessage(msg);

                return deferred.promise;
            }

            function callRep(msg) {
                if (_.has(_calls, msg.id)) {
                    if (msg.error) _calls[msg.id].reject(msg.body);
                    else _calls[msg.id].resolve(msg.body);
                    _.unset(_calls, msg.id);
                }

                msg.destroy();
            }

            function sub(topic, namespace, callback) {
                if (!_.isString(topic)) return false;
                if (_.isFunction(namespace)) {
                    callback = namespace;
                    namespace = "default";
                }
                else if (!_.isString(namespace) || !_.isFunction(callback)) return false;

                let msg = getWsMessage(wsMessage.prototype.SUB, topic);
                let deferred = $q.defer();

                _notifications[msg.id] = deferred;

                if (_.isUndefined(_subs[topic])) _subs[topic] = {};
                if (_.isUndefined(_subs[topic][namespace])) _subs[topic][namespace] = new Subject();

                _subs[topic][namespace].subscribe(callback);

                connection.sendMessage(msg);
                return deferred.promise;
            }

            function reSub() {
                _.forEach(_subs, (ns, topic) => {
                    let msg = getWsMessage(wsMessage.prototype.SUB, topic);
                    connection.sendMessage(msg);
                });
            }

            function unsub(topic, namespace) {
                if (!_.isString(topic)) return false;
                if (!_.isString(namespace)) namespace = "default";

                let deferred = $q.defer();

                if (!_.isUndefined(_subs[topic])) {
                    if (!_.isUndefined(_subs[topic][namespace])) {
                        _subs[topic][namespace].complete("");
                        _.unset(_subs[topic], namespace);

                        setTimeout(() => deferred.resolve(true), 500);
                    }

                    if (_.isEmpty(_subs[topic])) {
                        _.unset(_subs, topic);
                        let msg = getWsMessage(wsMessage.prototype.UNSUB, topic);
                        connection.sendMessage(msg);

                        _notifications[msg.id] = deferred;
                    }
                }

                return deferred.promise;

            }

            function pub(topic, body, exclude, sendTo) {
                if (_.isUndefined(exclude)) exclude = true;
                if (_.isUndefined(exclude) || _.isUndefined(sendTo)) sendTo = [];

                let msg = getWsMessage(wsMessage.prototype.PUB, topic);
                let deferred = $q.defer();

                _notifications[msg.id] = deferred;

                msg.body = body;
                msg.exclude = exclude;
                msg.send_to = sendTo;
                connection.sendMessage(msg);

                return deferred.promise;
            }

            function pubRep(msg) {
                _.forEach(_subs[msg.topic], subject => subject.next(msg.body, msg.error));

                msg.destroy();
            }

            function notified(msg) {
                if (_.has(_notifications, msg.id)) {
                    if (msg.error) _notifications[msg.id].reject(false);
                    else _notifications[msg.id].resolve(true);

                    _.unset(_notifications, msg.id);
                }

                msg.destroy();
            }

            function logout() {
                return connection.logout();
            }

            function getWsMessage(type, topic) {
                return new wsMessage(type, topic);
            }

            return self;
        }

        return $get;
    }

})();
