/**
 * Created by amine on 21/04/2022.
 */
(function () {

    'use strict';

    class ExamContainerCtrl {
        constructor($state, examService, visitService, $transitions, $transition$, observationService, $scope, $q, $translate, $mdToast) {
            this.state = $state;
            this.scope = $scope;
            this.q = $q;
            this.examService = examService;
            this.visitService = visitService;
            this.observationService = observationService;
            this.translate = $translate;
            this.toast = $mdToast;

            this.listener = $transitions.onBefore({}, (transition) => this.toList(transition));
            this.currentPatient = _.get($transition$.params('to'), 'pId');
            this.observationId = _.get($transition$.params('to'), 'observationId');

            this.lock = false;
            this.observationContext = !!this.observationId
            this.showModel = true;
            this.subscriptions = {};
            this.initModels = [];
            this.initExams = [];
            this.currentTemplate = null;
        }

        static get $inject() {
            return [
                "$state", "examService", "visitService", "$transitions", "$transition$", "observationService",
                "$scope", "$q", "$translate", "$mdToast"
            ];
        }

        $onInit() {
            if (this.state.is("app.visit.exam")) this.examService.listState();

            this.subscriptions.sideNaveSubscription = this.examService.sideNaveSubject
                .subscribe(lock => this.lock = _.isNil(lock) ? false : lock);
            this.subscriptions.formSubscription = this.examService.formSubject
                .subscribe(isForm => this.isForm = isForm);
            this.subscriptions.selectedTemplateSubscription = this.examService.selectedTemplate
                .subscribe(template => {
                    if (!_.has(template, "id")) template = {id: template}
                    this.filterExamModels(template, true);
                });
            this.subscriptions.currentModelSubscription = this.examService.currentModelSubject
                .subscribe(model => this.currentModel = model);


            this.subscriptions.modelsSubscription = this.examService.examModelSubject
                .subscribe(status => {
                    if (_.has(status, "added")) {
                        this.initModels = _.pushOrUpdate(this.initModels, status.added);
                        this.filterExamModels(status.added.template);
                    }

                    if (_.has(status, "deleted")) {
                        let index = _.findIndex(this.initModels, {id: status.deleted});
                        this.initModels.splice(index, 1);
                    }
                });
            this.subscriptions.examSubscription = this.examService.examSubject
                .subscribe(status => {
                    if (_.has(status, "loaded")) {

                    }
                    if (_.has(status, "added")) {
                        this.initExams = _.pushOrUpdate(this.initExams, status.added);
                        this.filterExamModels(status.added.template, true);
                    }

                    if (_.has(status, "deleted")) {
                        let index = _.findIndex(this.initExams, {id: status.deleted});
                        this.initExams.splice(index, 1);

                        this.list = this.showModel ? this.models : this.initExams;
                    }
                });

            this.subscriptions.currentPatientSubscription = this.visitService.currentPatientSubject
                .subscribe(patient => this.patient = patient);

            this.examService.getExamModels().then(data => {
                this.initModels = data;
                this.filterExamModels(this.currentTemplate, true);
            });

            this.scope.$on("$destroy", () => this.onDestroy())
        }

        onDestroy() {
            if (_.isFunction(this.listener)) this.listener();

            if (!!this.subscriptions.sideNaveSubscription) this.subscriptions.sideNaveSubscription.unsubscribe();
            if (!!this.subscriptions.currentModelSubscription) this.subscriptions.currentModelSubscription.unsubscribe();
            if (!!this.subscriptions.formSubscription) this.subscriptions.formSubscription.unsubscribe();
            if (!!this.subscriptions.selectedTemplateSubscription) this.subscriptions.selectedTemplateSubscription.unsubscribe();
            if (!!this.subscriptions.modelsSubscription) this.subscriptions.modelsSubscription.unsubscribe();
            if (!!this.subscriptions.currentPatientSubscription) this.subscriptions.currentPatientSubscription.unsubscribe();
            if (!!this.subscriptions.examSubscription) this.subscriptions.examSubscription.unsubscribe();
        }

        filterExamModels(template, skipList = false) {
            if (!template || !template.id) template = this.examService.selectedTemplate.getValue();
            if (template === this.currentTemplate) return false;

            const sortFunc = (item) => {
                return _.chain(item.title).deburr().toLower().value();
            }

            this.currentTemplate = template;

            if (template && _.has(template, "id")) {
                this.models = _.chain(this.initModels)
                    .filter({template_id: template.id})
                    .sortBy(item => sortFunc(item))
                    .value();

                this.reloadExams(this.currentTemplate).then(() => {
                    this.list = this.showModel ? this.models : this.initExams;
                });
            } else {
                this.models = _.sortBy(this.initModels, item => sortFunc(item));
                this.initExams = [];
                this.showModel = true;
                this.list = this.models;
            }
        }

        reloadExams(template) {
            let deferred = this.q.defer();

            const query = {
                patient: this.currentPatient,
                limit: false,
                page: false,
                order: "-exam_date",
                search: {
                    templates: [template.id]
                }
            }
            this.examService.getExamListTable(query).then(data => {
                deferred.resolve(data.list);
                this.initExams = data.list;
            });

            return deferred.promise;
        }

        useItem(item) {
            if (_.has(item, "exam_date")) {
                this.editItem(item)
            } else {
                this.useModel(item)
            }
        }

        useModel(model) {
            this.examService.newState(model.template, model, !this.isForm)
                .then(() => {
                    const simpleToast = this.toast.simple()
                        .textContent(this.translate.instant("exam_use_model_msg"))
                        .position("bottom left")
                        .hideDelay(2000);

                    this.toast.show(simpleToast);

                    if (this.isForm) {
                        this.examService.contentSubject.next({use: true, ...model});
                    }
                });
        }

        addModel(model) {
            this.examService.contentSubject.next({use: false, ...model});
            const simpleToast = this.toast.simple()
                .textContent(this.translate.instant("exam_add_model_msg"))
                .position("bottom left")
                .hideDelay(2000);

            this.toast.show(simpleToast);
        }

        editItem(item) {
            this.examService.editState(item, this.currentTemplate, true);
        }

        duplicateItem(item) {
            this.examService.duplicateState(item, this.currentTemplate);
        }

        deleteItem(item) {
            this.examService.removeExam(item, this.currentPatient)
                .then(_.noop, _.noop);
        }

        toggleModels() {
            this.showBiologyExamTree = false;
            this.showModel = !this.showModel;
            this.list = this.showModel ? this.models : this.initExams;
        }

        listState() {
            this.examService.listState();
        }

        editModel(model) {
            this.examService.editModelState(model);
        }

        duplicateModel(model) {
            this.examService.duplicateModelState(model);
        }

        moveModel(model, ev) {
            this.examService.moveExamModel(model, ev);
        }

        deleteModel(model, ev) {
            this.examService.deleteExamModel(model, ev);
        }

        returnToObservation(id) {
            this.observationService.returnTo(id);
        }

        toList(transition) {
            let state = transition.to();

            if (!_.eq(state.name, "app.visit.exam.list-view") && _.eq(state.name, "app.visit.exam")) {
                this.examService.listState();

                return false;
            }

            return true;
        }

    }

    module.exports = ExamContainerCtrl;

})();
