/**
 * Created by Betalos on 30/07/2016.
 */
(function () {

    'use strict';

    module.exports = ConsultationListCtrl;

    ConsultationListCtrl.$inject = [
        "$state", "$transition$", "$transitions", "consultationService", "$q", "configService", "$scope", "visitService", "system",
        "$translate", "moment", "observationService"
    ];

    function ConsultationListCtrl(
        $state, $transition$, $transitions, consultationService, $q, configService, $scope, visitService, system,
        $translate, moment, observationService
    ) {

        let vm = this;

        let initList = [];
        let currentPatient = _.get($transition$.params('to'), 'pId');

        let dateFormat = system['date_format'].js;

        vm.$onInit = init;

        vm.viewConsult = viewConsult;
        vm.copyConsult = copyConsult;
        vm.editConsult = editConsult;
        vm.newConsultation = newConsultation;
        vm.openObservation = openObservation;

        vm.removeConsult = removeConsult;
        vm.pushNewConsult = pushNewConsult;
        vm.showBookmarked = showBookmarked;
        vm.getGroupDays = getGroupDays;

        vm.removeMobileConsultation = removeMobileConsultation;

        vm.updateVisit = updateVisit;

        function init() {
            vm.lock = true;
            vm.favorite = false;
            vm.currentActiveConsultId = vm.currentConsultId = vm.currentObservationId = -1;
            vm.summaryInitQuery = {start: moment().format(dateFormat), end: moment().format(dateFormat)};
            vm.currentVisit = _.get($transition$.params('to'), 'visitId');

            vm.useRootConfig = _.get(configService, "activeModules.use_root_config", false);

            let listener = null;
            const favoriteSubscription = consultationService.favoriteSubject(handleFavorite);
            const newItemSubscription = consultationService.newItemSubscription(pushNewConsult);
            const currentSubscription = consultationService.currentSubscription.subscribe(handleCurrent);
            const currentObservationSubscription = observationService.currentSubscription.subscribe(handleCurrentObservation);
            const newObservationSubscription = observationService.newItemSubscription.subscribe(pushNewEntry);
            const lockSubscription = consultationService.lockSubject.subscribe(handleLock);
            const currentVisitSubscription = visitService.currentVisitSubject.subscribe(handleVisit);
            const currentPatientSubscription = visitService.currentPatientSubject.subscribe(handlePatient);

            const promises = [
                consultationService.getConfig(),
                consultationService.getConsultationList(currentPatient),
                consultationService.getMobileConsultations(currentPatient),
                observationService.get(currentPatient),
                configService.get("consultation_config", !vm.useRootConfig),
                configService.get("observation_config")
            ];

            vm.promise = $q.all(promises)
                .then(success);


            $scope.$on("$destroy", onDestroy);

            function success(data) {
                vm.consultationConfig = data[4];
                vm.observationConfig = data[5];

                const observations = _.reduce(data[3], reduceObservation, []);
                let disabledTemplates = _.map(vm.consultationConfig['disabled'], (item, key) => item ? key : null);
                vm.templates = _.remove(data[0], item => !_.includes(disabledTemplates, item.key));

                initList = _.chain(data[1]).concat(data[2], observations).orderBy('sort_date', 'desc').value();
                vm.consultationList = sortConsultationList(initList);

                vm.lastConsultationDuration = getLastConsultationDuration();

                decideConsult();

                listener = $transitions.onBefore({to: 'app.visit.consultation'}, () => {
                    vm.lock = true;
                    vm.currentEditConsult = -1;
                    vm.currentActiveConsultId = -1;
                    vm.currentConsultationDate = null;

                    return true;
                });
            }

            function onDestroy() {
                if (!!favoriteSubscription) favoriteSubscription.unsubscribe();
                if (!!newItemSubscription) newItemSubscription.unsubscribe();
                if (!!currentSubscription) currentSubscription.unsubscribe();
                if (!!lockSubscription) lockSubscription.unsubscribe();
                if (!!currentVisitSubscription) currentVisitSubscription.unsubscribe();
                if (!!currentPatientSubscription) currentPatientSubscription.unsubscribe();
                if (!!currentObservationSubscription) currentObservationSubscription.unsubscribe();
                if (!!newObservationSubscription) newObservationSubscription.unsubscribe();

                if (_.isFunction(listener)) listener();
            }

            function handleLock(item) {
                vm = _.assign(vm, item);
            }

            function handleVisit(data) {
                vm.visit = data;
            }

            function handlePatient(data) {
                vm.patient = data;
            }

            function handleCurrent(data) {
                //to get consultation date for the patient report
                const currentDate = _.get(data, "currentConsultationDate", false);

                if (currentDate) vm.summaryInitQuery = {start: currentDate, end: currentDate};

                vm = _.assign(vm, _.omit(data, ['currentConsultationDate']));
            }

            function handleCurrentObservation(data) {
                vm.currentObservationId = data;
            }
        }

        function newConsultation(value) {
            if (value === 'consultation' && _.get(vm.consultationConfig, "use_observation_as_default", false))
                return openObservation(false);

            vm.currentObservationId = [];

            $state.go("app.visit.consultation.body", {
                'is-last': true,
                'read-only': false,
                'consultation': null,
                'template': _.isString(value) ? getDefaultTmp(value) : value,
            }).then($state.reload);
        }

        function openObservation(showLast = true) {
            return $state.go("app.visit.consultation.observation", {
                'entry': null,
                'read-only': false,
                'observation': null,
                'show-last': showLast
            }).then($state.reload);
        }

        function getDefaultTmp(key) {
            return _.chain(vm.templates).find({'key': vm.consultationConfig[key]}).get('id').value();
        }

        function viewConsult(item) {
            let state = "body";
            let type = "consultation";
            let isMobile = false;
            let params = {};

            if (item.visit_id !== vm.currentVisit && item.id === vm.currentActiveConsultId) return;
            if (item.visit_id === vm.currentVisit && vm.currentEditConsult && [item.id, isMobile].toString() === vm.currentEditConsult.toString()) return;

            if (_.get(item, 'is_mobile', false)) {
                isMobile = true;
                type = "mobile";
            } else if (_.get(item, 'isObservation', false)) type = "observation";

            switch (type) {
                case "consultation":
                    vm.currentObservationId = [];

                    params = {
                        'consultation': item.id,
                        'is-last': isFirst(item),
                        'template': item['template_id'],
                        'read-only': item.visit_id !== vm.currentVisit
                    };
                    break;
                case "mobile":
                    state = "mobile";
                    vm.currentConsultId = -1;
                    params = {consultation: item.id};
                    break;
                case "observation":
                    vm.currentEditConsult = [-1, false];

                    state = "observation";
                    vm.currentConsultId = -1;
                    params = {entry: item.original_id, observation: item.observation.id};
                    break;
            }

            $state.go(`app.visit.consultation.${state}`, params).then(isMobile ? _.noop : $state.reload);
        }

        function copyConsult(item) {
            $state.go("app.visit.consultation.body", {
                'copy': item.id,
                'is-last': true,
                'read-only': false,
                'consultation': null,
                'template': item['template_id'],
            }).then(vm.currentActiveConsultId === -1 ? $state.reload : _.noop);
        }

        function editConsult(item) {
            if (vm.currentEditConsult.toString() === [item.id, false].toString()) return false;

            else $state.go("app.visit.consultation.body", {
                'read-only': false,
                'consultation': item.id,
                'is-last': isFirst(item),
                'template': item['template_id'],
            }).then($state.reload);
        }

        function removeConsult(item, ev) {
            consultationService.removeConsultation(item, currentPatient, ev)
                .then(() => removeSuccess(item));
        }

        function removeMobileConsultation(item, ev) {
            consultationService.removeMobileConsultation(item, ev)
                .then(() => removeSuccess(item));
        }

        function removeSuccess(item) {
            initList = _.without(initList, item);
            vm.consultationList = sortConsultationList(initList);

            decideConsult(true);
        }

        function decideConsult(remove) {
            const ignore =
                $state.is('app.visit.consultation.body') ||
                $state.is('app.visit.consultation.mobile') ||
                $state.is('app.visit.consultation.observation');

            if (!remove && ignore) return;

            let currentVisitConsultation = _.find(initList, ['visit_id', vm.currentVisit]);
            if (initList.length === 0 && $state.current.name !== 'app.visit.consultation') $state.go("app.visit.consultation", {}, {location: 'replace'});
            else if (_.isUndefined(currentVisitConsultation)) {
                const lastConsult = _.head(initList);
                const params = {
                    'is-last': true,
                    'read-only': true,
                    'template': _.get(lastConsult, 'template_id', null)
                };

                goToConsultation(lastConsult, params);
            } else {
                const params = {
                    'is-last': true,
                    'read-only': false,
                    'template': _.get(currentVisitConsultation, 'template_id', null)
                };

                goToConsultation(currentVisitConsultation, params);
            }
        }

        function goToConsultation(item, consultParams) {
            let type = "consultation";
            let state = "body";
            let params = {};

            if (_.get(item, 'is_mobile', false)) {
                type = "mobile";
            } else if (_.get(item, 'isObservation', false)) {
                type = "observation";
            }

            switch (type) {
                case "consultation":
                    vm.currentObservationId = [];
                    vm.currentConsultId = item.id;
                    params = _.assign({consultation: item.id}, consultParams);
                    break;
                case "mobile":
                    state = "mobile";
                    vm.currentConsultId = -1;
                    params = {consultation: item.id};
                    break;
                case "observation":
                    vm.currentEditConsult = [null, false];

                    state = "observation";
                    vm.currentConsultId = -1;
                    params = {entry: item.original_id, observation: item.observation.id};
                    break;
            }


            return $state.go(`app.visit.consultation.${state}`, params);
        }

        function handleFavorite(item) {
            const match = _.chain(initList)
                .find(i => i.id === item.id && !i['is_mobile'])
                .assign({'is_bookmarked': item.is_bookmarked})
                .value();

            if (match && match.consultation_date !== item.consultation_date) refreshList();
        }


        function showBookmarked() {
            vm.favorite = !vm.favorite;
            vm.consultationList = sortConsultationList(vm.favorite ? _.filter(initList, 'is_bookmarked') : initList);
        }

        function updateVisit() {
            visitService.updateVisit(vm.visit, 'visit_date')
                .then(() => refreshList());
        }

        function refreshList() {
            vm.promise = $q.all([
                consultationService.getConsultationList(currentPatient),
                consultationService.getMobileConsultations(currentPatient),
                observationService.get(currentPatient)
            ]).then(data => {
                const observations = _.reduce(data[2], reduceObservation, []);
                initList = _.concat(data[0], data[1], observations);
                vm.consultationList = sortConsultationList(initList);
            });
        }

        function pushNewConsult(id) {
            vm.promise = consultationService.getConsultationListItem(id)
                .then(data => {
                    vm.currentConsultId = id;
                    vm.currentActiveConsultId = -1;
                    vm.currentEditConsult = [id, false];

                    initList = _.pushOrUpdate(initList, data);
                    vm.consultationList = sortConsultationList(initList);
                });
        }

        function sortConsultationList(list) {
            return _.chain(list).groupBy('consultation_date').toPairs().sortBy(
                item => -1 * moment(item[0], dateFormat).valueOf()
            ).value();
        }

        function pushNewEntry(ids) {
            refreshList();
            vm.currentObservationId = ids;
        }

        function reduceObservation(result, item) {
            const newItem = _.chain(item.entries)
                .orderBy('entry_date', 'asc')
                .groupBy('entry_date')
                .map(_.first)
                .forEach(entry => {
                    entry.original_id = entry.id;
                    entry.id = `e_${entry.id}`;
                    entry.observation = _.omit(item, 'entries');
                    entry.isObservation = true;
                    entry.consultation_date = entry.entry_date;
                })
                .value();

            return _.concat(result, newItem);
        }

        function getGroupDays($index) {
            let currentDate = _.get(vm.consultationList, `${$index}.0`);
            let lastDate = _.get(vm.consultationList, `${$index + 1}.0`);

            if (!currentDate || !lastDate) return null;

            let diff = moment(currentDate, dateFormat).diff(moment(lastDate, dateFormat));
            return moment.duration(diff).humanize();
        }

        function getLastConsultationDuration() {
            let lastConsultIndex = moment().format(dateFormat) === _.get(vm.consultationList, '0.0', '') ? 1 : 0;

            let currentDate = _.get(vm.consultationList, `${lastConsultIndex}.0`);

            if (!currentDate) return $translate['instant']('first_consultation');

            let diff = moment().diff(moment(currentDate, dateFormat));

            return $translate['instant']('last_consultation', {
                last_consultation: moment.duration(diff).humanize()
            });
        }

        function isFirst(item) {
            return _.findIndex(initList, _.pick(item, 'id')) === 0;
        }

    }

})();
