/**
 * Created by BETALOS on 18/12/2015.
 */
(function () {

    'use strict';

    const fullScreenFunc = require('shared/utils/full-screen');
    const PLAN_DIALOG = require('../dialogs/treatment-plan-dialog');
    const PLAN_STOCK_DIALOG = require('../dialogs/dental-plan-stock-dialog');

    const PLANNING_DIALOG = require('frontdesk/dialogs/planning-dialog');
    const QUOTATION_DIALOG = require('../dialogs/dental-quotation-dialog');

    const CHART_HEIGHT_STORAGE_KEY = 'chart-height-storage-key';

    module.exports = DentalContainerCtrl;

    DentalContainerCtrl.$inject = [
        "dentalService", "$transition$", "$q", "system", "$scope", "mnWebSocket", "$timeout", "$mdDialog", "$translate",
        "interfacingService", "visitService", "dentalUtilsService", "uploadService", "$mdToast", "moment", "patientService",
        "storageService", "dragulaService"
    ];

    function DentalContainerCtrl(
        dentalService, $transition$, $q, system, $scope, mnWebSocket, $timeout, $mdDialog, $translate,
        interfacingService, visitService, dentalUtilsService, uploadService, $mdToast, moment, patientService,
        storageService, dragulaService
    ) {
        let vm = this;

        const bag = "item-bag";
        const dateFormat = system['date_format'].js;

        const visitDate = sortDateKey('visit_date');
        const creationDate = sortDateKey('creation_date');

        vm.$onInit = init;

        vm.isSearching = false;
        vm.filterMenu = filterMenu;

        vm.preSave = saveConsultation;
        vm.updateVisit = updateVisit;

        vm.saveCondition = _.debounce(saveCondition, 500);
        vm.savePlanDetail = _.debounce(savePlanDetail, 500);
        vm.saveConsultation = _.debounce(saveConsultation, 500);
        vm.saveQuotationDetail = _.debounce(saveQuotationDetail, 500);

        vm.setCurrentTab = setCurrentTab;
        vm.handleDisabled = handleDisabled;
        vm.chooseProcedure = chooseProcedure;

        vm.removeConsultationItem = removeConsultationItem;
        vm.removeConditionItem = removeConditionItem;

        vm.showConsultation = showConsultation;
        vm.consultationTotal = consultationTotal;
        vm.removeCurrentViewConsultation = removeCurrentViewConsultation;

        // treatment plan
        vm.handlePlanEdit = handlePlanEdit;
        vm.removePlan = removePlan;
        vm.showDetailPlan = showDetailPlan;
        vm.removeCurrentPlan = removeCurrentPlan;
        vm.removePlanDetail = removePlanDetail;
        vm.addPlanning = addPlanning;
        vm.deliveryForm = deliveryForm;
        vm.applyProcedure = applyProcedure;
        vm.refreshProgress = refreshProgress;
        vm.showPlanSteps = showPlanSteps;

        // Treatment plan && quotation
        vm.linesTotal = linesTotal;

        // dental quotation
        vm.handleQuotationEdit = handleQuotationEdit;
        vm.duplicateQuotation = duplicateQuotation;
        vm.showDetailQuotation = showDetailQuotation;
        vm.deleteQuotationItem = deleteQuotationItem;
        vm.changeToPlan = changeToPlan;
        vm.removeQuotation = removeQuotation;
        vm.applyFromQuotation = applyFromQuotation;
        vm.removeCurrentQuotation = removeCurrentQuotation;

        // SubItemsFilter
        vm.filterItems = filterItems;

        // show files context
        vm.openFiles = openFiles;
        vm.showContextFiles = showContextFiles;

        //schema parent callbacks
        vm.callbacks = {
            uploadFiles, toothHistoric, showContextFiles
        };

        // full screen
        vm.fullScreen = fullScreen;

        // file upload
        vm.uploadFiles = uploadFiles;

        // stand alone actions
        vm.goBack = goBack;

        function init() {
            vm.teeth = require('../json/teeth.json');

            // current height related
            vm.chartHeights = require('../json/chart-heights.json');
            vm.setCurrentHeight = item => {
                vm.currentHeight = item;
                storageService.setKey(CHART_HEIGHT_STORAGE_KEY, item);
            }

            vm.isVisit = _.chain($transition$.to()).get('name').startsWith('app.visit').value();

            vm.currentPatient = _.get($transition$.params('to'), 'pId');
            vm.currentVisit = _.get($transition$.params('to'), 'visitId');

            vm.isFullScreen = document['webkitIsFullScreen'];
            if (vm.isFullScreen) handleFullScreenChange();

            vm.chosenProcedure = null;
            vm.chartID = _.uniqueId('svg-parent-');
            vm.currentTab = vm.isVisit ? 'procedureDone' : 'patientPlans';

            vm.plans = [];
            vm.quotations = [];
            vm.consultations = [];
            vm.currentTooth = null;
            vm.appliedProcedures = [];
            vm.currentEditPlan = false;
            vm.currentEditQuotation = false;
            vm.currentViewConsultation = false;

            vm.fileFilter = {};
            vm.currentPlan = {id: -1};

            // quotation order handling
            dragulaService.options($scope, bag, {
                revertOnSpill: false,
                moves: function (el, container, handle) {
                    return $(handle).is('.md-button.drag-handle:not([disabled])') || $(handle).is('.drag-handle:not([disabled]) > .mdi-drag');
                }
            });

            const drake = dragulaService.find($scope, bag).drake;
            drake.on("dragend", vm.saveQuotationDetail);

            let currentVisitSubscription = null;
            let currentPatientSubscription = null;
            let chartSubscription = dentalService.chartSubject.subscribe(handleChart);

            const promises = [
                dentalService.getPatientConsultations(vm.currentPatient),
                dentalService.getPatientCondition(vm.currentPatient),
                dentalService.getPatientPlans(vm.currentPatient),
                dentalService.getPatientQuotations(vm.currentPatient),
                storageService.getKey(CHART_HEIGHT_STORAGE_KEY)
            ];

            vm.promise = $q.all(promises)
                .then(success);

            function success(data) {
                vm.patientCondition = data[1];

                vm.plans = _.orderBy(data[2], creationDate, 'desc');
                vm.quotations = _.orderBy(data[3], creationDate, 'desc');
                vm.consultations = _.orderBy(data[0], visitDate, 'desc');

                vm.currentHeight = data[4] || vm.chartHeights[2];

                vm.currentConsultation = _.find(vm.consultations, {visit_id: vm.currentVisit});

                if (_.isNil(vm.currentConsultation) && vm.isVisit) vm.currentConsultation = {
                    teeth_procedures: [],
                    visit_id: vm.currentVisit,
                    visit: {id: vm.currentVisit},
                    visit_date: moment().format(dateFormat),
                };

                if (vm.isVisit) {
                    currentVisitSubscription = visitService.currentVisitSubject.subscribe(data => vm.visit = data);
                    currentPatientSubscription = visitService.currentPatientSubject.subscribe(data => {
                        vm.patient = data;
                        vm.dentalProcedureGroups = dentalService.dentalProcedureGroups.getValue(); // reduce load time
                    });
                } else patientService.getMinimalPatient(vm.currentPatient).then(data => {
                    vm.patient = data;
                    vm.dentalProcedureGroups = dentalService.dentalProcedureGroups.getValue(); // reduce load time
                });

                $scope.$watch('vm.currentTab', (newValue, oldValue) => {
                    if (newValue === 'initialStatus' || oldValue === 'initialStatus' || (oldValue && oldValue === newValue)) refreshProcedures();
                });
            }

            $scope.$on('$destroy', onDestroy);

            function onDestroy() {
                if (chartSubscription) chartSubscription.unsubscribe();
                if (currentVisitSubscription) currentVisitSubscription.unsubscribe();
                if (currentPatientSubscription) currentPatientSubscription.unsubscribe();

                $(document.body).removeClass('dental-module-fullscreen');
                $(document).off("fullscreenchange webkitfullscreenchange", handleFullScreenChange);

                mnWebSocket.unsub("dental_consultation.Dental.payments_update");
            }

            $(document).on("fullscreenchange webkitfullscreenchange", handleFullScreenChange);
            mnWebSocket.sub("dental_consultation.Dental.payments_update", data => handlePaymentUpdates(data));
        }

        // refresh when price changes
        function handlePaymentUpdates(data) {
            if (data.visit) {
                if (vm.isVisit && vm.currentConsultation.visit_id === data.visit) {
                    vm.visitPromise = dentalService.getConsultationDetail(vm.currentConsultation)
                        .then(data => vm.currentConsultation = data, _.noop);
                } else if (vm.currentViewConsultation.visit_id === data.visit) {
                    vm.visitPromise = dentalService.getConsultationDetail(vm.currentViewConsultation)
                        .then(data => vm.currentViewConsultation = data, _.noop);
                }
            }
        }

        function filterMenu(key) {
            let text = "";
            if (key.length === 0) vm.items = vm.dentalProcedureGroups;
            else {
                text = _.chain(key).toLower().deburr().value();
                vm.items = _.reduce(vm.dentalProcedureGroups, reduceGroup, []);
            }

            vm.isSearching = key.length > 0;

            function reduceGroup(items, group) {
                const label = _.chain(group.name).toLower().deburr().value();
                if (_.includes(label, text)) items.push(group);

                else {
                    const sublist = _.reduce(group['dental_procedures'], handleSubItems, []);

                    if (sublist.length > 0) {
                        const gps = _.chain(group).cloneDeep().assign({dental_procedures: sublist}).value();
                        items.push(gps);
                    }
                }

                return items;
            }

            function handleSubItems(sublist, item) {
                const label = _.chain(item.name).toLower().deburr().value();
                if (_.includes(label, text)) sublist.push(item);

                return sublist;
            }
        }

        function updateVisit() {
            vm.promise = visitService.updateVisit(vm.visit, 'visit_date')
                .then(() => refreshConsultations());
        }

        function refreshConsultations() {
            let promises = [dentalService.getPatientConsultations(vm.currentPatient)];

            if (vm.currentEditPlan) promises.push(showDetailPlan(vm.currentEditPlan));

            vm.promise = $q.all(promises)
                .then(data => {
                    vm.consultations = _.orderBy(data[0], visitDate, 'desc');
                    vm.currentConsultation = _.find(vm.consultations, {visit_id: vm.currentVisit});

                    if (_.isNil(vm.currentConsultation) && vm.isVisit) vm.currentConsultation = {
                        teeth_procedures: [],
                        visit_id: vm.currentVisit,
                        visit: {id: vm.currentVisit},
                        visit_date: vm.visit.visit_date,
                    };
                });
        }

        function saveConsultation(consultation, index) {
            if (vm.isVisit) return dentalService.saveConsultation(consultation, vm.currentPatient)
                .then(data => {
                    if (index) data.teeth_procedures[index] = consultation.teeth_procedures[index];
                    saveConsultationSuccess(data);
                });
            else return $q.all([]);
        }

        function saveConsultationSuccess(data) {
            const assignedData = _.pick(data, ['teeth_procedures', 'procedures_prices']);

            if (vm.currentViewConsultation) {
                vm.currentViewConsultation = _.assign(vm.currentViewConsultation, assignedData);
                vm.consultations = _.map(vm.consultations, item => item.id === vm.currentViewConsultation.id ? vm.currentViewConsultation : item);
                return refreshProcedures();
            }

            if (!_.has(vm.currentConsultation, 'id')) {
                vm.currentConsultation = _.assign(vm.currentConsultation, _.pick(data, ['id', 'visit_date', 'visit_id']));
                vm.consultations = _.chain(vm.consultations)
                    .concat(vm.currentConsultation).orderBy(visitDate, 'desc').value();
            }

            vm.currentConsultation = _.assign(vm.currentConsultation, assignedData);
            vm.consultations = _.map(vm.consultations, item => item.id === vm.currentConsultation.id ? vm.currentConsultation : item);

            refreshProcedures();
        }

        function saveCondition(index) {
            let items = _.map(vm.patientCondition['teeth_conditions'], handleConditionMap);

            vm.patientCondition['teeth_conditions'] = sortConditionList(items);

            dentalService.saveCondition(vm.patientCondition)
                .then(success);

            function success(data) {
                if (index) data.teeth_procedures[index] = vm.patientCondition.teeth_procedures[index];

                vm.patientCondition = data;
                refreshProcedures();
            }

            function handleConditionMap(item) {
                let obj = {};

                if (_.has(item, 'dental_procedure') || !_.isNil(item['dental_procedure_id'])) {
                    obj['dental_procedure'] = _.get(item, 'dental_procedure', {id: item['dental_procedure_id']});
                }

                return _.assign(item, obj);
            }
        }

        function setCurrentTab(tab) {
            vm.currentTab = tab;
            if (!tab || tab === "patientPlans") vm.chosenProcedure = null;
        }

        function handleDisabled() {
            if (vm.currentTab === 'initialStatus' || vm.currentTab === 'procedureDone') return false;
            else if (vm.currentTab === 'currentEditPlan') return _.get(vm.currentEditPlan, 'is_blocked', false);
            else if (vm.currentTab === 'currentEditQuotation') return _.get(vm.currentEditQuotation, 'exported', false);
            else if (vm.currentTab === 'currentViewConsultation') return _.get(vm.currentViewConsultation, 'exported', false);
            else return true;
        }

        function chooseProcedure(dentalProcedure) {
            if (vm.chosenProcedure === dentalProcedure) vm.chosenProcedure = null;
            else vm.chosenProcedure = dentalProcedure;
        }

        function refreshProcedures() {
            vm.fileFilters = dentalUtilsService.handleFilters(vm.consultations, vm.plans);

            dentalUtilsService.proceduresApplied(
                vm.currentTab,
                vm.chartID,
                vm.patientCondition,
                vm.consultations,
                vm.currentEditPlan || vm.currentEditQuotation
            ).then(style => vm = _.assign(vm, style));
        }

        function sortConditionList(list, direction = 'desc') {
            return _.orderBy(list, [i => moment(i.applied_at, dateFormat).valueOf(), 'sort_by'], [direction, direction]);
        }

        function sortDateKey(key) {
            return item => parseFloat(moment(item[key], dateFormat).valueOf());
        }

        // chart related
        function handleChart(data) {
            if (vm.currentEditPlan) handleAllMouthProcedure(vm.currentEditPlan, data).then(item => handlePlan(item));
            else if (vm.currentEditQuotation) handleAllMouthProcedure(vm.currentEditQuotation, data).then(item => handleQuotation(item));
            else if (vm.currentViewConsultation) handleAllMouthProcedure(vm.currentViewConsultation, data).then(
                item => handleProcedures(vm.currentViewConsultation, item)
            );
            else if (vm.currentTab === 'procedureDone') handleAllMouthProcedure(vm.currentConsultation, data).then(
                item => handleProcedures(vm.currentConsultation, item)
            );
            else if (vm.currentTab === 'initialStatus') handleAllMouthProcedure(vm.patientCondition, data, 'teeth_conditions').then(
                item => handleInitialStatus(item)
            );
        }

        function handleAllMouthProcedure(view, procedure, path) {
            let deferred = $q.defer();
            const procedures = _.get(view, path || 'teeth_procedures', []);
            const isAllMouth = _.get(procedure, 'dental_procedure.is_all_mouth');
            const existence = _.find(procedures, item => _.get(item, 'dental_procedure_id') === _.get(procedure, 'dental_procedure.id'))

            if (isAllMouth && existence) {
                let confirm = $mdDialog.confirm()
                    .ariaLabel('remove plan confirm')
                    .ok($translate.instant('dental_module_ok'))
                    .cancel($translate.instant('dental_module_cancel'))
                    .title($translate.instant('dental_module_add_all_mouth_confirm', procedure));

                $mdDialog.show(confirm).then(() => deferred.resolve(procedure), () => deferred.reject(false));
            } else deferred.resolve(procedure);

            return deferred.promise;
        }

        function handleProcedures(consultation, data) {
            let id = _.get(data, 'dental_procedure.procedure_id', null);
            let procedures = id ? [{
                id, price: data.price,
                comment: $translate.instant('dental_procedure_applied_from', data),
            }] : [];

            dentalService.refreshFinancialStatus(consultation.visit_id, procedures)
                .then(uid => startSave(consultation, uid, data));
        }

        function startSave(consultation, procedures_uid, data) {
            let appliedProcedure = _.assign(data, {procedure_uid: _.head(procedures_uid)});

            consultation.teeth_procedures.unshift(appliedProcedure);
            vm.saveConsultation(consultation);
        }

        function handleInitialStatus(data) {
            let appliedProcedure = _.assign(data, {
                applied_at: moment().format(dateFormat),
            });

            vm.patientCondition['teeth_conditions'].push(appliedProcedure);
            vm.patientCondition['teeth_conditions'] = sortConditionList(vm.patientCondition['teeth_conditions'], 'asc');

            vm.saveCondition();
        }

        function removeConsultationItem(consultation, procedure) {
            const index = _.indexOf(consultation['teeth_procedures'], procedure);
            const procedures_uid = _.chain(procedure).get('procedure_uid', []).castArray().value();

            vm.visitPromise = $q.all([
                procedures_uid ? dentalService.removeFinancialStatus(consultation.visit_id, procedures_uid) : true,
                mnWebSocket.call('dental_consultation.Dental.remove_procedure', {
                    item: _.get(consultation, 'id'), index
                })
            ]).then(success);

            function success(data) {
                consultation['teeth_procedures'].splice(index, 1);
                refreshProcedures();

                const lastData = _.last(data);
                const planItem = _.pick(lastData, 'id');
                const i = _.findIndex(vm.plans, planItem);

                if (i !== -1 && lastData) vm.plans.splice(i, 1, lastData);

                vm.saveConsultation(consultation);
            }
        }

        function removeConditionItem(item) {
            vm.patientCondition['teeth_conditions'] = _.without(vm.patientCondition['teeth_conditions'], item);

            vm.saveCondition();
            refreshProcedures();
        }

        function showConsultation(consultation) {
            if (consultation.id !== _.get(vm.currentConsultation, 'id', -1)) vm.currentViewConsultation = consultation;
            else vm.currentTab = 'procedureDone';
        }

        function consultationTotal(consultation) {
            if (!consultation || !consultation['procedures_prices'] || consultation['procedures_prices'].length === 0) return 0;
            else {
                const total = _.reduce(
                    consultation['procedures_prices'], (result, item) => result + (item['price'] - item['discount']), 0
                );

                return _.isNaN(total) ? 0 : total;
            }
        }

        function removeCurrentViewConsultation() {
            $timeout(() => {
                vm.currentViewConsultation = false;
            }, 500);
        }

        // treatment plan
        function handlePlanEdit(plan, ev) {
            const dialog = _.assign({}, PLAN_DIALOG, {
                targetEvent: ev,
                locals: {
                    plan: _.cloneDeep(plan),
                    patient: vm.currentPatient
                }
            });

            $mdDialog.show(dialog)
                .then(success);

            function success(data) {
                vm.plans = _.chain(vm.plans).pushOrUpdate(data).orderBy(creationDate, 'desc').value();
                if (data.isNew) showDetailPlan(data);
            }
        }

        function savePlan(plan) {
            dentalService.savePlan(plan)
                .then(success);

            function success(data) {
                vm.plans = _.chain(vm.plans).pushOrUpdate(data).orderBy(creationDate, 'desc').value();
            }
        }

        function removePlan(plan, index, ev) {
            let confirm = $mdDialog.confirm()
                .ariaLabel('remove plan confirm')
                .targetEvent(ev)
                .ok($translate.instant('dental_module_ok'))
                .cancel($translate.instant('dental_module_cancel'))
                .title($translate.instant('dental_module_remove_plan_confirm', plan));

            $mdDialog.show(confirm).then(() => {
                dentalService.removePlan(plan).then(removeFromList);
            });

            function removeFromList() {
                vm.plans.splice(index, 1);
            }
        }

        function showDetailPlan(plan) {
            return dentalService.planDetail(plan)
                .then(success);

            function success(data) {
                vm.currentEditPlan = data;
                refreshProcedures();
            }
        }

        function handlePlan(data) {
            vm.currentEditPlan.teeth_procedures.unshift(data);
            vm.savePlanDetail();
        }

        function removePlanDetail(procedure) {
            if (vm.planForm.$invalid || vm.currentEditPlan.is_blocked || !procedure.can_remove || procedure.is_done) return;

            vm.currentEditPlan.teeth_procedures = _.without(vm.currentEditPlan.teeth_procedures, procedure);
            vm.savePlanDetail();
        }

        function savePlanDetail(index) {
            if (vm.planForm.$valid) vm.planTablePromise = dentalService.savePlanDetail(vm.currentEditPlan)
                .then(success);

            function success(data) {
                if (index) data.teeth_procedures[index] = _.assign(
                    {}, data.teeth_procedures[index],
                    vm.currentEditPlan.teeth_procedures[index]
                );

                vm.currentEditPlan = data;

                refreshProcedures();
                updatePlanTable(data);
            }
        }

        function updatePlanTable(plan) {
            vm.planTablePromise = dentalService.getPlanTable(plan)
                .then(success);

            function success(data) {
                vm.plans = _.pushOrUpdate(vm.plans, data);
            }
        }

        function applyProcedure(procedure) {
            const index = _.indexOf(vm.currentEditPlan['teeth_procedures'], procedure);

            if (procedure['is_done'] && _.isNil(procedure['tooth_procedure_uid'])) handleApplyPlanProcedure(procedure, index);
            else if (!_.isNil(procedure['tooth_procedure_uid'])) handleUnApplyPlanProcedure(procedure, index);
        }

        function refreshProgress(procedure) {
            const index = _.indexOf(vm.currentEditPlan['teeth_procedures'], procedure);

            if (procedure['progress'] === 1 && _.isNil(procedure['tooth_procedure_uid'])) handleApplyPlanProcedure(procedure, index);
            else if (procedure['progress'] < 1 && !_.isNil(procedure['tooth_procedure_uid'])) handleUnApplyPlanProcedure(procedure, index);
            else if (procedure['progress'] < 1 && _.isNil(procedure['tooth_procedure_uid'])) savePlanDetail();
        }

        function handleApplyPlanProcedure(procedure, index) {
            // DOES NOT MAKE SENSE FROM OUTSIDE
            // BUT IT THE ONLY WAY TO SET "treatment_plan_uid" AND "procedure_uid", THING THAT SHOULD BE DONE
            // TO HAVE A CORRECT PAYMENT, BILLING, AND STATISTICS
            // "system.Sys.get_object_id" GENERATE A ObjectId IN THE BACKEND SIDE AND RETURN IT TO BE USED

            mnWebSocket.call('system.Sys.get_object_id', {}).then(objId => {
                let teeth_procedure = _.chain(procedure)
                    .cloneDeep()
                    .omit('applied_by')
                    .assign({
                        treatment_plan: _.pick(vm.currentEditPlan, 'id')
                    })
                    .value();

                vm.currentConsultation.teeth_procedures.unshift(teeth_procedure);
                vm.currentConsultation.teeth_procedures = _.map(vm.currentConsultation.teeth_procedures, item => {
                    if (
                        (_.get(item, "treatment_plan_id") || _.get(item, "treatment_plan.id")) &&
                        !_.get(item, "treatment_plan_uid") &&
                        !_.get(item, "procedure_uid")
                    ) {
                        console.log(item, objId)
                        return _.assign({}, item, {
                            procedure_uid: objId,
                            treatment_plan_uid: objId
                        })
                    }

                    return item;
                });

                vm.planPromise = dentalService.saveConsultation(vm.currentConsultation, vm.currentPatient)
                    .then(updatePlan);
            });

            function updatePlan(data) {
                saveConsultationSuccess(data);

                let uid = _.chain(data.teeth_procedures).first().get('treatment_plan_uid', null).value();
                vm.currentEditPlan.teeth_procedures.splice(index, 1, _.assign(procedure, {
                    progress: 1,
                    tooth_procedure_uid: uid,
                    dental_consultation: _.pick(data, 'id')
                }));

                savePlanDetail();
                mnWebSocket.pub('dental_consultation.Dental.payments_update', {visit: data.visit_id}, false);
            }
        }

        function handleUnApplyPlanProcedure(procedure, index) {
            vm.planPromise = mnWebSocket.call('dental_consultation.Dental.unset_plan_procedure', {
                item: vm.currentEditPlan.id, index, progress: procedure['progress']
            }).then(startUpdate);

            function startUpdate(data) {
                if (!data) return;

                vm.plans = _.pushOrUpdate(vm.plans, data.plan);
                vm.consultations = _.pushOrUpdate(vm.consultations, data.consultation);

                vm.currentConsultation = _.find(vm.consultations, {visit_id: vm.currentVisit});

                vm.currentEditPlan.preview = data.preview;
                vm.currentEditPlan.teeth_procedures.splice(index, 1, data.detail);

                refreshProcedures();
            }
        }

        function removeCurrentPlan() {
            $timeout(() => {
                vm.currentEditPlan = false;
                refreshProcedures();
            }, 250);
        }

        //
        function showPlanSteps(procedure, ev) {
            dentalUtilsService.planProcedureSteps(procedure, ev)
                .then(data => {
                    procedure['step_comments'] = data;
                    vm.savePlanDetail();
                });
        }

        function addPlanning(plan, ev, index) {
            $mdDialog.show(_.assign({}, PLANNING_DIALOG, {
                targetEvent: ev,
                locals: {
                    patient_id: plan.patient_id,
                    sessions_nbr: plan.sessions_nbr,
                    currentPlanningId: plan['planning_id'],
                    comment: $translate.instant("dental_treatment_plan_planning_comment", _.pick(plan, 'title')),
                }
            })).then(doneCallBack);

            function doneCallBack(data) {
                plan.planning = _.pick(data, 'id');
                savePlan(plan, index);
            }
        }

        function deliveryForm(plan, ev, fromList) {
            $mdDialog.show(_.assign({}, PLAN_STOCK_DIALOG, {
                locals: {plan},
                targetEvent: ev,
            })).then(data => {
                if (!fromList) {
                    plan.has_deliveries = data;
                    _.chain(vm.plans).find(['id', plan.id]).tap(p => p.has_deliveries = true).value();
                }
            }, _.noop);
        }

        // quotation
        function handleQuotationEdit(quotation, ev) {
            const dialog = quotationDialog(quotation, ev);

            $mdDialog.show(dialog)
                .then(success);

            function success(data) {
                vm.quotations = _.chain(vm.quotations).pushOrUpdate(data).orderBy(creationDate, 'desc').value();
                if (data.isNew) showDetailQuotation(data);
            }
        }

        function duplicateQuotation(quotation, ev) {
            const dialog = quotationDialog(quotation, ev);

            $mdDialog.show(dialog)
                .then(success);

            function success(data) {
                vm.quotations = _.chain(vm.quotations).pushOrUpdate(data).orderBy(creationDate, 'desc').value();
                if (data.isNew) showDetailQuotation(data);
            }
        }

        function quotationDialog(quotation, ev) {
            return _.assign({}, QUOTATION_DIALOG, {
                targetEvent: ev,
                locals: {
                    patient: vm.currentPatient,
                    duplicate: _.cloneDeep(quotation),
                }
            });
        }

        function showDetailQuotation(quotation) {
            dentalService.getQuotationDetail(quotation)
                .then(success);

            function success(data) {
                vm.currentEditQuotation = data;
                refreshProcedures();
            }
        }

        function handleQuotation(procedure) {
            vm.currentEditQuotation.teeth_procedures.unshift(procedure);
            vm.saveQuotationDetail();
        }

        function deleteQuotationItem(procedure) {
            vm.currentEditQuotation.teeth_procedures = _.without(vm.currentEditQuotation.teeth_procedures, procedure);
            vm.saveQuotationDetail();
        }

        function changeToPlan(quotation, ev) {
            const dialog = _.assign({}, PLAN_DIALOG, {
                targetEvent: ev,
                locals: {
                    quotation: quotation,
                    patient: vm.currentPatient
                }
            });

            $mdDialog.show(dialog)
                .then(success);

            function success(data) {
                vm.plans = _.chain(vm.plans).pushOrUpdate(data).orderBy(creationDate, 'desc').value();

                vm.showDetailPlan(data)
                    .then(() => vm.setCurrentTab('currentEditPlan'));
            }
        }

        function linesTotal(item) {
            if (!item || item['teeth_procedures'].length === 0) return 0;
            else {
                const total = _.chain(item['teeth_procedures'])
                    .filter(item => !item.is_refused)
                    .reduce((result, item) => result + (item['price'] - item['discount']), 0)
                    .value();

                return _.isNaN(total) ? 0 : total;
            }
        }

        function saveQuotationDetail(index) {
            if (vm.quotationForm.$valid) vm.quotationTablePromise = dentalService.saveQuotationDetail(vm.currentEditQuotation)
                .then(success);

            function success(data) {
                if (index) data.teeth_procedures[index] = _.assign(
                    {}, data.teeth_procedures[index],
                    vm.currentEditQuotation.teeth_procedures[index]
                );

                vm.currentEditQuotation = data;
                refreshProcedures();
                refreshQuotationTable(data);
            }
        }


        function refreshQuotationTable(item) {
            vm.quotationTablePromise = dentalService.getQuotationTable(item)
                .then(data => vm.quotations = _.pushOrUpdate(vm.quotations, data));
        }

        function removeQuotation(quotation, index, ev) {
            let confirm = $mdDialog.confirm()
                .targetEvent(ev)
                .ariaLabel('remove plan confirm')
                .ok($translate.instant('dental_module_ok'))
                .cancel($translate.instant('dental_module_cancel'))
                .title($translate.instant('dental_module_quotation_remove_confirm', quotation));

            $mdDialog.show(confirm).then(() => {
                dentalService.removeQuotation(quotation).then(removeFromList);
            });

            function removeFromList() {
                vm.quotations.splice(index, 1);
            }
        }

        function applyFromQuotation(procedure) {
            let procedureId = _.get(procedure, 'procedure_id', null);
            let dentalProcedureId = _.get(procedure, 'dental_procedure_id', null);

            let procedures = _.chain(procedure).pick(['price', 'discount']).assign(
                {id: procedureId, comment: $translate.instant('dental_procedure_applied_from', procedure)}
            ).castArray().value();

            let consultationItem = _.chain(procedure).pick(['code', 'name', 'teeth']).assign({
                comment: $translate.instant('dental_quotation_applied_from', vm.currentEditQuotation)
            }).assign(dentalProcedureId ? {dental_procedure: {id: dentalProcedureId}} : {}).value();

            vm.quotationTablePromise = dentalService.refreshFinancialStatus(vm.currentConsultation.visit_id, procedures)
                .then(uid => {
                    startSave(vm.currentConsultation, uid, consultationItem);
                    if (_.isEmpty(procedure.comment)) {
                        procedure.comment = $translate.instant('dental_quotation_was_applied', vm.currentConsultation);
                        saveQuotationDetail();
                    }
                });
        }

        function removeCurrentQuotation() {
            $timeout(() => {
                vm.currentEditQuotation = false
                refreshProcedures();
            }, 250);
        }

        // subItems filter
        function filterItems(items) {
            if (!items) return [];
            else return vm.currentTooth ? _.filter(items, item => _.includes(item.teeth, vm.currentTooth)) : items;
        }

        // full screen
        function fullScreen() {
            if (document['webkitIsFullScreen']) fullScreenFunc.cancelFullScreen();
            else fullScreenFunc.goFullScreen();
        }

        function handleFullScreenChange() {
            if (document['webkitIsFullScreen']) {
                vm.isFullScreen = true;
                $(document.body).addClass('dental-module-fullscreen');
            } else {
                vm.isFullScreen = false;
                $(document.body).removeClass('dental-module-fullscreen');
            }

            $scope.$applyAsync();
        }

        // schema callbacks
        function toothHistoric(tooth, ev) {
            dentalUtilsService.toothHistoric(vm.currentPatient, vm.patientCondition, vm.consultations, tooth, ev);
        }

        // files context show
        function openFiles(context, event) {
            vm.planPromise = uploadService.rootContextFiles('patient', vm.currentPatient)
                .then(data => filesSuccess(data, event), () => filesError(context));
        }

        function filesSuccess(items, event) {
            const visualizableItems = _.filter(items, 'is_visualizable');

            if (visualizableItems.length === 0) filesError();
            else {
                const files = _.filter(visualizableItems, 'is_converted');
                uploadService.visualizeFiles(files, 0, event, false, false);
            }
        }

        function filesError() {
            let simpleToast = $mdToast.simple()
                .textContent($translate.instant('dental_setup_plan_no_files_found'))
                .position("bottom left")
                .hideDelay(1500);

            $mdToast.show(simpleToast);
        }

        function showContextFiles(context) {
            vm.filesTab = true;
            vm.fileFilter = context;
        }

        // file upload
        function uploadFiles(ev, context) {
            if (_.chain(vm.currentConsultation).get('id').isNil().value() && vm.isVisit) vm.preSave(vm.currentConsultation).then(openDialog);
            else openDialog();

            function openDialog() {
                const contextHandled = _.assign(
                    {teeth: [vm.currentTooth] || []},
                    vm.currentConsultation ? {dental_consultation: vm.currentConsultation.id} : {},
                    context || {}
                );

                dentalUtilsService.openUploadDialog(
                    vm.currentPatient, vm.consultations, vm.plans, contextHandled, ev
                );
            }
        }

        // stand alone actions
        function goBack() {
            window.history.back();
        }
    }

})();
