/**
 * Created by amine on 04/12/2020.
 */
(function () {
    'use strict';

    class AclFilter {
        constructor(authService) {
            this.authService = authService;
            this.filterCall = input => this.filter(input);
        }

        static create() {
            return new AclFilter(...arguments).filterCall;
        }

        filter(input) {
            return this.authService.aclHandler(input)
        }

    }

    AclFilter.create.$inject = ['authService'];

    module.exports = AclFilter.create;


})();