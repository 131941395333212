(function () {

    'use strict';

    const generateId = require('shared/utils/unique-id');

    class StorageService {
        constructor($q, mnWebSocket) {
            this.$q = $q;
            this.ws = mnWebSocket;

            this.promises = {};
            this.worker = new Worker(new URL('../workers/storageWorker.js', import.meta.url));  // new Worker();
            this.worker.onmessage = ev => this.handleWorkerMessages(ev);

            this.perUserData = [
                "user-dictionary",
                "default-values",

            ]

            // this.ws.sub("shared.Storage.reset_cache", '', data => {
            //     this.removeKey(data.key);
            // });
        }

        static get $inject() {
            return ['$q', 'mnWebSocket'];
        }

        setKey(key, value) {
            this.worker.postMessage({cmd: 'set', key, value});
        }

        getKey(key) {
            let id = generateId();
            this.promises[id] = this.$q.defer();

            this.worker.postMessage({id, cmd: 'get', key});

            return this.promises[id].promise;
        }

        removeKey(key) {
            this.worker.postMessage({cmd: 'remove', key});
        }

        handleWorkerMessages(ev) {
            let promise = _.get(this.promises, ev.data.id);

            if (promise) {
                _.unset(this.promises, ev.data.id)
                promise.resolve(ev.data.value);
            }
        }

        loadPromise(key, promise, attr = null) {
            const deferred = this.$q.defer();

            this.getKey(key)
                .then(item => {
                    if (!!item) {
                        if (!!attr) {
                            item = _.set({}, attr, item)
                        }

                        deferred.resolve(item);
                    } else {
                        if (_.isArray(promise)) {
                            if (!_.isFunction(promise[0])) {
                                return false;
                            }

                            const func = promise.shift();
                            promise = func(...promise)
                        }
                        promise.then(item => {
                            if (!!attr) {
                                this.setKey(key, _.get(item, attr));
                            } else {
                                this.setKey(key, item);
                            }

                            deferred.resolve(item);
                        })
                    }
                });
            //return promise;
            return deferred.promise;
        }

        resetValue(key) {
            console.warn(".resetValue(key) is deprecated, use .this.removeKey(key)")
            this.removeKey(key);
        }

        clear() {
            this.perUserData.map(key => {
                this.removeKey(key);
            });
        }

    }

    module.exports = StorageService;

})();
