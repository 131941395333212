/**
 * Created by BETALOS on 16/01/2017.
 * Edited by Amine on 25/10/2021
 */
(function () {

    'use strict';

    class ExamLinksCtrl {
        constructor(examService, configService, $q, $element) {
            this.examService = examService;
            this.configService = configService;
            this.q = $q;
            this.element = $element;

            this.config = {
                disabled: {},
                favorite: {},
                order: {}
            };
            this.templateSubscription = null;


        }

        static get $inject() {
            return ["examService", "configService", "$q", "$element"];
        }

        $onInit() {
            this.hasLock = this.element.is('[lock]');
            this.change = this.change || undefined;

            if (this.element.is(".consultation-exam-cuts")) {
                $(".md-menu", this.element.is(".consultation-exam-cuts"))
                    .addClass("layout-row");
            }

            this.q.all([
                this.examService.getExamTemplates(),
                this.configService.get("exam_config", true)
            ]).then(data => {
                this.config = _.assign(this.config, data[1]);
                let templates = _.reduce(data[0], (templates, item) => {
                    if (!_.get(this.config.disabled, item.id)) {
                        item.order = _.get(this.config.order, item.id, 9999);

                        if (!!_.get(this.config.favorite, item.id)) {
                            templates.fav.push(item);
                        } else {
                            templates.nofav.push(item);
                        }
                    }

                    return templates;
                }, {
                    fav: [],
                    nofav: []
                });

                this.templates = _.sortBy(templates.nofav, "order");
                this.favoritesTemplates = _.sortBy(templates.fav, "order");

                this.templateSubscription = this.examService.selectedTemplate
                    .subscribe(template => {
                        if (_.isNumber(template)) {
                            this.examService.selectedTemplate.next(
                                _.find(_.concat(this.templates, this.favoritesTemplates), {id: template})
                            )
                        } else if (!_.isNil(template) && template) {
                            this.selectedTemplate = template;
                            this.activeMenu = !_.isNil(_.find(this.templates, template));
                        } else {
                            this.selectedTemplate = null;
                            this.activeMenu = false;
                        }
                    });
            });
        }

        $onDestroy() {
            if (this.templateSubscription) {
                this.examService.selectedTemplate.next(null);
                this.templateSubscription.unsubscribe();
            }
        }

        selectTemplate(template) {
            this.examService.selectedTemplate.next(template);
            if (!_.isNil(this.change) && _.isFunction(this.change)) this.change(template);
            else this.examService.newState(template, null, false)
        }

        lockChange() {
            this.lock = !this.lock;
        }
    }

    module.exports = {
        bindings: {
            lock: "=?",
            change: "<?templateChange"
        },
        template: require("./exam-links.tpl.html"),
        controllerAs: "vm",
        controller: ExamLinksCtrl,
    };

})();
