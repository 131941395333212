/**
 * Created by amine on 24/10/2016.
 */

(function () {

    "use strict";

    module.exports = wsConnection;

    const downloadJs = require('downloadjs');
    const Deferred = require("es6-deferred");
    const wsMessage = require("./wsMessage");

    function wsConnection(options) {
        let self = this;
        let restartTimeOut = 2000, restartTimes = 0, restartMax = 15;
        let address = `${location.protocol === 'https:' ? 'wss' : 'ws'}://${location.hostname}:${location.port}/ws/`;

        let worker = null;
        let dead = false;

        let authDefer = null;
        let logOutDefer = null;

        let delayedMessages = [];

        self.handleMessage = _.noop;
        self.sendMessage = sendMessage;
        self.authentication = authentication;
        self.logout = logout;

        connect();

        function connect() {
            if (worker) {
                worker.terminate();
                worker = null;
            }

            worker = new Worker(new URL('../workers/wsWorker.js', import.meta.url)); // new Worker();
            worker.postMessage({'cmd': 'connect', address});

            worker.onmessage = function (ev) {
                switch (ev.data.cmd) {
                    case 'open':
                        open();
                        break;
                    case 'close':
                        reconnect();
                        break;
                    case 'message':
                        receiveMessage(ev.data.msg);
                        break;
                }
            }
        }

        function reconnect() {
            if (dead) return false;

            options.subject.next(false);
            options.connectionSubject.next(false);

            if (++restartTimes <= restartMax) {
                options.warn("ws disconnected ... reconnecting");
                setTimeout(connect, restartTimeOut);
            } else {
                options.warn("ws disconnected");
            }
        }

        function open() {
            if (restartTimes === 0) options.log("ws connection opened");
            else options.log("ws reconnected");

            options.subject.next(true);
            options.connectionSubject.next(true);
        }

        function receiveMessage(msg) {
            msg.destroy = _.bind(destroy, msg);

            if (_.has(msg.body, "download")) {
                const currentMsg = _.clone(msg);
                msg.body.download = () => _downloadFile(currentMsg)
            }

            if (msg.type === wsMessage.prototype.AUTHETICATION) authentication_rep(msg);
            else if (msg.type === wsMessage.prototype.LOGOUT) logoutRep(msg);
            else self.handleMessage(msg);
        }

        function _downloadFile(message) {
            let deferred = new Deferred();
            const body = message.body;

            setTimeout(() => {
                downloadJs(body._content, _.replace(body._name, /[#%&{}\\<>*?/$!'":@+`|=\s]/gi, "_"));
                deferred.resolve(true);
            });

            return deferred.promise;
        }

        function sendMessage(msg) {
            if (options.subject.getValue()) {
                worker.postMessage({'cmd': 'send', msg: JSON.stringify(msg)});
            } else if (msg.type !== 0) {
                delayedMessages.push(_.cloneDeep(msg));
            }

            msg.destroy();
        }

        function authentication($auth) {
            authDefer = new Deferred();

            let msg = new wsMessage(wsMessage.prototype.AUTHETICATION, false);
            msg.body = $auth.getToken();

            sendMessage(msg);

            return authDefer.promise;
        }

        function logout() {
            logOutDefer = new Deferred();

            let msg = new wsMessage(wsMessage.prototype.LOGOUT, false);
            msg.body = "";

            sendMessage(msg);

            return logOutDefer.promise;
        }

        function logoutRep(msg) {
            if (!logOutDefer) setTimeout(() => {
                localStorage.clear();
                location.reload();
            }, 250);
            else if (msg.error) logOutDefer.reject(msg.body);
            else logOutDefer.resolve(true);

            msg.destroy();
        }

        function authentication_rep(msg) {
            if (msg.error) {
                authDefer.reject(msg.body);
            } else {
                authDefer.resolve(true);

                _.forEach(delayedMessages, sendMessage);
                delayedMessages = [];
            }

            msg.destroy();
        }

        // function getBody(body) {
        //     if (_.has(body, '__content__')) {
        //         if (body['__exception__'] && options.debug) options.error(body['__content__']);
        //         body = body['__content__'];
        //     }
        //
        //     if (_.has(body, '__file__')) {
        //         body = {
        //             _name: body['__name__'],
        //             _content: body['__body__'],
        //             //download: _.bind(downloadFile, body)
        //         }
        //     }
        //
        //     return body;
        //
        //     // function downloadFile() {
        //     //     let message = this, d = new Deferred();
        //     //
        //     //     _.defer(_f);
        //     //     return d.promise;
        //     //
        //     //     function _f() {
        //     //         download(message['__body__'], message['__name__']);
        //     //         d.resolve(true);
        //     //     }
        //     // }
        // }

        function destroy() {
            this.id = null;
            this.type = null;
            this.topic = null;
            this.body = null;
            this.error = null;
            this.exclude = null;
            this.send_to = null;
        }
    }
})();
