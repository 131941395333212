/**
 * Created by Hp on 03/05/2017.
 */


(function () {
    'use strict';

    module.exports = InvoiceFormCtrl;

    const CARE_PLAN_SEARCH_DIALOG = require('billing/dialogs/care-plan-search/care-plan-search-dialog');

    InvoiceFormCtrl.$inject = ["conventionService", "dragulaService", "$stateParams", "patientService", "$translate", "system", "$mdDialog", 'billingService', "saleService", "$state", "$mdToast", "$scope", "visitService", "configService"];

    function InvoiceFormCtrl(conventionService, dragulaService, $stateParams, patientService, $translate, system, $mdDialog, billingService, saleService, $state, $mdToast, $scope, visitService, configService) {
        let vm = this;
        let currentInvoiceId = $stateParams['invoiceId'];
        let currentVisits = $stateParams['visits'];
        let currentBeneficiary = $stateParams['beneficiary'];
        let currentBeneficiaryType = $stateParams['beneficiaryType'];
        let isContract = $stateParams['isContract'];
        let invoiceDate = $stateParams['invoiceDate'];
        let invoiceCart = $stateParams['invoiceCart'];


        vm.$onInit = init;
        let dateFormat = system['date_format'].js;
        vm.removePatient = removePatient;
        vm.patientCallback = patientCallback;
        vm.onPaginate = onPaginate;
        vm.SelectVisit = SelectVisit;
        vm.showProcedures = showProcedures;
        vm.removeVisit = removeVisit;
        vm.removeProcedure = removeProcedure;

        vm.getTotalPrice = getTotalPrice;
        vm.getTotalDiscount = getTotalDiscount;
        vm.invoiceTotal = invoiceTotal;

        vm.selectDelivery = selectDelivery;
        vm.selectArticle = selectArticle;
        vm.selectStockArticle = selectStockArticle;
        vm.addEmptyLine = addEmptyLine;
        vm.submit = submit;
        vm.validateInvoice = validateInvoice;
        vm.invalidateInvoice = invalidateInvoice;
        vm.removeInvoice = removeInvoice;
        vm.cancel = goBack;
        vm.SelectProcedure = SelectProcedure;
        vm.getSubTotal = getSubTotal;
        vm.SelectVisitProcedure = SelectVisitProcedure;
        vm.SelectTreatmentPlan = SelectTreatmentPlan;
        vm.selectCarePlan = selectCarePlan;
        //vm.SelectVisitProcedure = SelectVisitProcedure;
        vm.SelectDentalConsultation = SelectDentalConsultation;
        vm.prePrint = prePrint;
        $scope.$watch('vm.invoice.is_valid', readOnlyInvoice);
        let bag = "line-bag";

        function init() {
            vm.isDental = configService.isDental();
            vm.selectedVisit = null;
            vm.invoice = {};
            vm.invoice_details = [];
            vm.filter = {is_deleted: {"$ne": true}, is_draft: {"$ne": true}};
            vm.is_new = !_.isNil(currentInvoiceId) ? false : true;
            vm.mnModel = 'Invoice';
            vm.query = {
                limit: 5,
                page: 1,
                order: "-id"
            }
            vm.options = [5, 10, 20];
            vm.paginationLabel = {
                page: $translate.instant('page'),
                rowsPerPage: $translate.instant('rowsPerPage'),
                of: $translate.instant('of')
            }


            if (!_.isNil(currentInvoiceId)) {
                vm.promise = billingService
                    .getInvoice(currentInvoiceId)
                    .then(success);
            } else {
                if ($stateParams['patientId']) {
                    currentBeneficiary = {id: $stateParams['patientId']};
                    currentBeneficiaryType = 'PATIENT';
                }
                vm.promise = null;
                vm.invoice = {
                    date: isContract && invoiceDate ? invoiceDate : moment().format(dateFormat),
                    deadline_date: isContract && invoiceDate ? invoiceDate : moment().format(dateFormat),
                    invoice_details: [],
                    beneficiary_type: currentBeneficiaryType ? currentBeneficiaryType : 'PATIENT',
                    is_contract_invoice: isContract ? isContract : false
                };
                if (currentBeneficiary && currentBeneficiaryType === 'PATIENT') patientCallback(currentBeneficiary)
                else vm.invoice.beneficiary = currentBeneficiary;
                getData();
                if (!invoiceCart) {
                    loadVisits(currentVisits);
                } else {
                    handleCartItems();
                }

            }
            initDrag();
            vm.conventions = [];
            conventionService.getConventionsList().then(data => {
                vm.conventions = data; //todo complete convention integration
            })

            function success(data) {
                vm.invoice = data;
            }
        }

        function handleCartItems() {
            handleCartVisits();
            handleCartDentalVisits();
            handleCartTreatmentPlan();
            handleCartCarePlan();
            handleCartContracts();
            handleCartDeliveryForms();
        }

        function handleCartDeliveryForms() {
            selectDeliveryCallBack(_.get(invoiceCart, 'delivery_form'));
        }

        function handleCartContracts() {
            let contract_instances = _.get(invoiceCart, 'contract_instance');

            if (!_.isEmpty(contract_instances)) {
                _.forEach(contract_instances, current_instance => {
                    billingService.getContractInstance(current_instance.id).then(data => {
                        let details = _.reject(data.details, function (line) {
                            if (_.get(data, 'start_at') != _.get(data, 'parent.start_at')) {
                                return _.get(line, 'is_inclusive', false)
                            } else {
                                return false;
                            }
                        });
                        let invoice_detail = {
                            description: `${data.parent.type == 'SUBSCRIPTION' ? $translate.instant('add_subscription') : $translate.instant('add_renting')} N°${data.parent.number}`,
                            sub_details: _.map(details, (d) => {
                                return _.assign(_.pick(d, ['code', 'description', 'qte', 'price', 'sub_total']), {
                                    discount: 0,
                                    contract_instance: {id: current_instance.id},
                                    details_date: current_instance.next_bill_period || current_instance.start_at
                                })
                            })
                        }
                        if (!_.isEmpty(invoice_detail)) vm.invoice.invoice_details.push(invoice_detail)
                    })
                });
            }
        }


        function handleCartCarePlan() {
            const InvoicedPlans = [];
            vm.processedPlans = 0;
            let medical_care_plans = _.get(invoiceCart, 'medical_care_plan');

            if (!_.isEmpty(medical_care_plans)) {
                _.forEach(medical_care_plans, plan => {
                    billingService.getMedicalCarePlan(plan.id).then(data => {
                        loadCarePlanProcedure(data, InvoicedPlans, medical_care_plans.length);
                    })
                });
            }
        }

        function handleCartTreatmentPlan() {
            let InvoicedPlans = [];
            let noProceduresPlans = [];
            vm.processedPlans = 0;
            let treatment_plans = _.get(invoiceCart, 'treatment_plan');

            if (!_.isEmpty(treatment_plans)) {
                _.forEach(treatment_plans, function (plan) {
                    loadPlanProcedure(plan, InvoicedPlans, treatment_plans.length);
                })
            }
        }

        function handleCartDentalVisits() {
            let InvoicedVisits = [];
            vm.processedDentals = 0;
            let dental_consultations = _.get(invoiceCart, 'dental_consultation');
            if (!_.isEmpty(dental_consultations)) {
                _.forEach(dental_consultations, function (visit) {
                    billingService.getDentalConsulation(visit.id).then(data => {
                        loadDentalVisitProcedure(_.assignIn(visit, data), InvoicedVisits, dental_consultations.length);
                    });
                })
            }
        }

        function handleCartVisits() {


            let InvoicedVisits = [];
            let noProceduresVisits = [];
            vm.Processed = 0;
            let visits = _.get(invoiceCart, 'visit');

            _.forEach(visits, function (visit) {
                loadVisitProcedure(_.assignIn(visit, {
                    patient_fullname: `${_.get(visit, 'patient.first_name')} ${_.get(visit, 'patient.first_name')}`,
                }), InvoicedVisits, visits.length, noProceduresVisits, true);
            });
        }


        function readOnlyInvoice() {
            vm.readOnly = (vm.invoice && vm.invoice.is_valid) ? vm.invoice.is_valid : false;
        }

        function removePatient() {
            vm.invoice.beneficiary = null;
        }

        function initDrag() {
            dragulaService.options($scope, bag, {
                revertOnSpill: false,
                moves: function (el, container, handle) {
                    return $(handle).is('.md-button.drag-handle') || $(handle).is('.drag-handle > .mdi-drag');
                }
            });
            let drake = dragulaService.find($scope, bag).drake;
            drake.on("dragend", _.mnDelay(moveLine, 400));
        }

        function moveLine() {
        }

        function patientCallback(patient) {
            vm.promise = patientService.getMinimalPatient(patient.id, false)
                .then(doneCallback);

            function doneCallback(data) {
                vm.invoice.beneficiary = data;
            }
        }

        function onPaginate(page, limit) {
            vm.query = _.assign(vm.query, {page: page, limit: limit});
            getData();
        }

        function getData() {
            if (vm.invoice.invoice_details.length < ((vm.query.page - 1) * vm.query.limit)) {
                vm.query.page = 1;
            }
        }

        function SelectVisit(ev) {
            $mdDialog.show(_.assign(require('billing/dialogs/visit-search-dialog'), {
                targetEvent: ev,
                locals: {
                    beneficiary: vm.invoice.beneficiary,
                    beneficiary_type: vm.invoice['beneficiary_type']
                }
            })).then(function doneCallBack(data) {
                loadDefaultVisits(data);
            });
            getData();

        }

        function loadVisits(data) {
            let InvoicedVisits = [];
            let noProceduresVisits = [];
            vm.Processed = 0;
            if (isContract) {
                _.forEach(data, function (contract) {
                    billingService.getContract(contract.id).then(data => {
                        refreshLinesByContractLines(data, _.reject(data.current_instance.details, function (line) {
                            if (_.get(data, 'start_at') != _.get(data, 'current_instance.start_at')) {
                                return _.get(line, 'is_inclusive', false)
                            } else {
                                return false;
                            }
                        }));
                    })
                });
            } else {
                if (vm.isDental) {
                    _.forEach(data, function (visit) {
                        visitService
                            .getVisitOrDentalConsultation(visit.id)
                            .then(res => {
                                if (_.has(res, 'visit')) loadDentalVisitProcedure(res, InvoicedVisits, data.length);
                                else _.forEach(data, function (visit) {
                                    loadVisitProcedure(visit, InvoicedVisits, data.length, noProceduresVisits);
                                });
                            }, _.noop);
                    });
                } else {
                    _.forEach(data, function (visit) {
                        loadVisitProcedure(visit, InvoicedVisits, data.length, noProceduresVisits);
                    });
                }
            }
        }

        function loadDefaultVisits(data) {
            let InvoicedVisits = [];
            let noProceduresVisits = [];
            vm.Processed = 0;

            _.forEach(data, function (visit) {
                loadVisitProcedure(visit, InvoicedVisits, data.length, noProceduresVisits);
            });

        }

        function refreshLinesByContractLines(contract, details) {
            let invoice_detail = {
                description: `${contract.type == 'SUBSCRIPTION' ? $translate.instant('add_subscription') : $translate.instant('add_renting')} N°${contract.number}`,
                sub_details: _.map(details, (d) => {
                    return _.assign(_.pick(d, ['code', 'description', 'qte', 'price', 'sub_total']), {
                        discount: 0,
                        contract_instance: {id: contract.current_instance.id},
                        details_date: contract.current_instance.next_bill_period || contract.current_instance.start_at
                    })
                })
            }

            if (!_.isEmpty(invoice_detail)) vm.invoice.invoice_details.push(invoice_detail)
        }

        function refreshLinesByVisit(visit, visit_details, totallyInvoicedVisits) {
            let invoice_detail = {
                description: visit['patient_fullname'] + " , " + $translate.instant('visit_at') + " " + visit.visit_date,
                sub_details: visit_details
            };
            let visit_detail = _.chain(vm.invoice.invoice_details).find(function (item) {
                return _.chain(item.sub_details).filter(function (sub_detail) {
                    return !_.isNil(sub_detail.visit) && sub_detail.visit.id == visit.id
                }).value().length > 0 || item.description == invoice_detail.description
            }).value()

            if (!_.isNil(visit_detail)) {
                let sub_detail_append = _.filter(invoice_detail.sub_details, function (sub_detail) {
                    if (_.isEmpty(_.filter(visit_detail.sub_details, {code: sub_detail.code}))) return sub_detail;
                });

                visit_detail.sub_details = visit_detail.sub_details.concat(sub_detail_append);
            } else {
                if (!_.isEmpty(visit_details)) vm.invoice.invoice_details.push(invoice_detail);
                else totallyInvoicedVisits.push(visit.visit_date);
            }
        }

        function loadVisitProcedure(visit, InvoicedVisits, dataLength, noProceduresVisits, fromCart = false) {
            if (fromCart) {
                billingService.getVisitNonInvoicedProcedure(visit.id, vm.invoice.id).then(success);
            } else if (_.isEmpty(visit.procedures)) noProceduresVisits.push(visit.visit_date)
            else billingService.getVisitNonInvoicedProcedure(visit.id, vm.invoice.id).then(success);

            function success(data) {
                // TO RECTIFY AFTER TOTAL PROCEDURE DISCOUNT(amine)
                let visit_details = [];
                const proceduresPrices = _.get(data[1], 'procedures_prices', []); //ADDED

                _.forEach(data[0], function (procedure, index) {
                    let global_discount = _.chain(proceduresPrices).find({uid: procedure.uid}).get("global_discount", 0).value(); //ADDED
                    visit_details.push({
                        code: !_.isNil(procedure.procedure) ? procedure.procedure.code : procedure.name,
                        description: procedure.name,
                        qte: procedure.qte,
                        price: procedure.price, //EDITED
                        discount: (procedure.discount + global_discount) * procedure.qte, //EDITED
                        procedure_uid: procedure.uid,
                        visit: {id: visit.id}
                    });
                });
                // TO RECTIFY AFTER TOTAL PROCEDURE DISCOUNT(amine)
                refreshLinesByVisit(visit, visit_details, InvoicedVisits);
                vm.Processed += 1;
                if (vm.Processed == dataLength && InvoicedVisits.length > 0) {
                    showNotification($translate.instant('visits_totally_invoiced') +
                        (vm.isDental ? $translate.instant('visits_contains_dental_procedures_warning') : '') +
                        ': <br> ' + _.join(InvoicedVisits, '<br>'));
                }
            }
        }

        function showNotification(notification_content) {
            $mdDialog.show(
                $mdDialog.alert()
                    .parent($(document.body))
                    .clickOutsideToClose(true)
                    .title('')
                    .htmlContent(notification_content)
                    .ariaLabel('validation_warning')
                    .ok($translate.instant('confirm'))
            );
        }

        function showProcedures(idx) {
            if (vm.selectedVisit == idx) {
                vm.selectedVisit = -1;
            } else {
                vm.selectedVisit = idx;
            }
        }

        function removeVisit(index) {
            vm.invoice.invoice_details.splice((vm.query.page - 1) * vm.query.limit + index, 1);
        }

        function removeProcedure(line, item) {
            _.remove(_.find(vm.invoice.invoice_details, line).sub_details, item);
        }

        function refreshLinesByArticle(article_list, details) {
            _.forEach(article_list, function (article) {
                let detail_line = {
                    code: article.code,
                    description: article.short_desc,
                    qte: 1,
                    price: article.sale_price,
                    discount: 0.0,
                    article: _.cloneDeep(article)
                };
                let line_not_exist = _.isEmpty(_.filter(details, function (sub_detail) {
                    return sub_detail.code == detail_line.code;
                }));
                if (line_not_exist) details.push(detail_line);
            });
        }

        function selectStockArticle(line, ev) {
            $mdDialog.show(_.assign(require('stock/dialogs/article-search-dialog'), {
                targetEvent: ev,
                locals: {
                    search: {interdict_sale: {$ne: true}, is_deleted: {$ne: true}}
                }
            })).then(function selectLineCallBack(data) {
                refreshLinesByArticle(data, line.sub_details);
            });
        }

        function refreshByDeliveryLines(invoice_detail) {

            let delivery_detail = _.find(vm.invoice.invoice_details, {description: invoice_detail.description});
            if (!_.isNil(delivery_detail)) {
                let sub_detail_append = _.filter(invoice_detail.sub_details, function (sub_detail) {
                    if (_.isEmpty(_.filter(delivery_detail.sub_details, {code: sub_detail.code}))) return sub_detail;
                });
                delivery_detail.sub_details = delivery_detail.sub_details.concat(sub_detail_append);
            } else if (!_.isEmpty(invoice_detail.sub_details)) vm.invoice.invoice_details.push(invoice_detail);
        }

        function selectDeliveryCallBack(data) {
            _.forEach(data, function (delivery) {
                saleService.getDeliveryNonInvoicesLines(delivery.number).then(success);

                function success(lines) {
                    let invoice_detail = {
                        description: $translate.instant('delivery') + ' ' + delivery.number,
                        sub_details: []
                    };
                    _.forEach(lines, function (line) {
                        let detail_line = {
                            code: line.code,
                            description: line.short_desc,
                            qte: line.qte,
                            price: line.unit_price,
                            discount: 0.0,
                            article: _.cloneDeep(line.article),
                            delivery: _.cloneDeep(delivery)
                        };
                        let line_not_exist = _.isEmpty(_.filter(invoice_detail.sub_details, function (sub_detail) {
                            return sub_detail.code == detail_line.code;
                        }));
                        if (line_not_exist) invoice_detail.sub_details.push(detail_line);
                    });
                    refreshByDeliveryLines(invoice_detail);
                }
            });
        }

        function selectDelivery(ev) {
            $mdDialog.show(_.assign(require('stock/dialogs/document-search-dialog'), {
                targetEvent: ev,
                locals: {
                    modalIcon: "receipt-text",
                    modalTitle: "delivery_list",
                    modalModel: "stock.DeliveryForm",
                    search: _.assign({
                        is_deleted: {$ne: true},
                        is_valid: {$ne: false}
                    }, vm.invoice['beneficiary_type'] == 'PATIENT' ? {patient: vm.invoice.beneficiary.id} : {organization: vm.invoice.beneficiary.id}),
                }
            })).then(selectDeliveryCallBack);
        }

        function selectArticleCallBack(data) {
            let invoice_detail = {description: $translate.instant('other_supply'), sub_details: []};

            refreshLinesByArticle(data, invoice_detail.sub_details);

            let other_detail = _.find(vm.invoice.invoice_details, {description: invoice_detail.description});

            if (!_.isNil(other_detail)) {
                let sub_detail_append = _.filter(invoice_detail.sub_details, function (sub_detail) {
                    if (_.isEmpty(_.filter(other_detail.sub_details, {code: sub_detail.code}))) return sub_detail;
                });
                other_detail.sub_details = other_detail.sub_details.concat(sub_detail_append);
            } else if (!_.isEmpty(invoice_detail.sub_details)) vm.invoice.invoice_details.push(invoice_detail);
        }

        function selectArticle(ev) {
            $mdDialog.show(_.assign(require('stock/dialogs/article-search-dialog'), {
                targetEvent: ev,
                locals: {
                    search: {interdict_sale: {$ne: true}, is_deleted: {$ne: true}}
                }
            })).then(selectArticleCallBack);
        }

        function addEmptyLine() {
            vm.invoice.invoice_details.push({is_comment: true});
        }

        function getTotalPrice(invoice_detail) {
            return _.sumBy(invoice_detail.sub_details, function (sub_detail) {
                return sub_detail.sub_total || ((sub_detail.qte * sub_detail.price) - sub_detail.discount);
            });
        }

        function getTotalDiscount(invoice_detail) {
            return _.sumBy(invoice_detail.sub_details, 'discount');
        }

        function invoiceTotal() {
            return _.sumBy(vm.invoice.invoice_details, function (invoice_detail) {
                return getTotalPrice(invoice_detail);
            });
        }

        function getSubTotal(item) {
            return item.sub_total || (((item.qte | 0.0) * (item.price || 0.0)) - item.discount);
        }

        function assignBeneficiaryModel() {
            if (vm.invoice['beneficiary_type'] === 'PATIENT') vm.invoice.beneficiary = _.assign(vm.invoice.beneficiary, {
                _module: "patient.models",
                _model: "Patient"
            });
            if (vm.invoice['beneficiary_type'] === 'ORGANIZATION') vm.invoice.beneficiary = _.assign(vm.invoice.beneficiary, {
                _module: "shared.insurance.models",
                _model: "Organization"
            });
        }

        function submit(with_cancel) {
            if (!vm.prevent_save) {
                vm.prevent_save = true;
                assignBeneficiaryModel();
                vm.promise = billingService.saveInvoice(vm.invoice).then(success);
            }

            function success(data) {
                vm.prevent_save = false;

                if (with_cancel) {
                    goBack();
                } else {
                    saveSuccess(data);
                }
            }
        }

        function saveSuccess(data) {
            vm.invoice = data;
            if (_.isNil(currentInvoiceId)) $state.go('app.billing.invoice-form', {invoiceId: data.id}, {location: 'replace'});
            vm.prevent_invalidate = false;
            vm.prevent_validate = false;
        }

        function validateInvoice() {
            if (!vm.prevent_validate) {
                $mdDialog.show(
                    $mdDialog.confirm()
                        .parent($(document.body))
                        .clickOutsideToClose(true)
                        .title('')
                        .textContent($translate.instant('validation_warning'))
                        .ariaLabel('validation_warning')
                        .ok($translate.instant('confirm'))
                        .cancel($translate.instant('cancel'))
                ).then(function () {
                    vm.prevent_validate = true;
                    let contracts_ids = _.chain(vm.invoice.invoice_details).map('sub_details').flatten().filter((l) => {
                        return !_.isNil(l.contract_instance)
                    }).map('contract_instance.id').uniq().value();
                    if (!_.isEmpty(contracts_ids)) {
                        vm.promise = billingService.handleNextBillPeriod(contracts_ids).then((res) => {
                            vm.promise = billingService.updateBillingDocument(vm.invoice.id, 'invoice', {is_valid: true}).then((data) => {
                                vm.invoice = data
                            });

                        }, _.noop)
                    } else {
                        vm.promise = billingService.updateBillingDocument(vm.invoice.id, 'invoice', {is_valid: true}).then((data) => {
                            vm.invoice = data
                        });
                    }

                    // if (isContract && !_.has(vm.invoice, 'id') && !_.isEmpty(vm.invoice.invoice_details)) {
                    //     billingService.handleNextBillPeriod(currentVisits[0].id).then(_.noop())
                    // }

                })
            }
        }

        function invalidateInvoice() {
            if (!vm.prevent_invalidate) {
                $mdDialog.show(
                    $mdDialog.confirm()
                        .parent($(document.body))
                        .clickOutsideToClose(true)
                        .title('')
                        .textContent($translate.instant('invalidate_warning'))
                        .ariaLabel('invalidate_warning')
                        .ok($translate.instant('confirm'))
                        .cancel($translate.instant('cancel'))
                ).then(function () {
                    vm.prevent_invalidate = true;
                    let contracts_lines = _.chain(vm.invoice.invoice_details).map('sub_details').flatten().filter((l) => {
                        return !_.isNil(l.contract_instance)
                    }).value();

                    if (!_.isEmpty(contracts_lines)) {
                        if (_.every(contracts_lines, (line) => {
                            return line.details_date == line.contract_instance.prev_bill_date
                        })) {
                            vm.promise = billingService.handleNextBillPeriod(_.chain(contracts_lines).map('contract_instance.id').uniq().value(), true).then((data) => {
                                vm.promise = billingService.invalidateInvoice(vm.invoice.id).then(doneCallBack);
                            })
                        } else {
                            $mdDialog.show(
                                $mdDialog.alert()
                                    .parent($(document.body))
                                    .clickOutsideToClose(true)
                                    .title('')
                                    .textContent($translate.instant('contract_invoice_impossible_invalidate'))
                                    .ariaLabel('contract_invoice_impossible_invalidate')
                                    .ok('Ok')
                            );
                        }
                    } else {
                        vm.promise = billingService.invalidateInvoice(vm.invoice.id).then(doneCallBack);
                    }
                });
            }

            function doneCallBack(data) {
                vm.prevent_invalidate = false;

                $state.go('app.billing.invoice-form', {invoiceId: data.id}, {location: 'replace'});
                vm.invoice = data;
            }
        }

        function removeInvoice(invoice) {
            $mdDialog.show(
                $mdDialog.confirm()
                    .parent($(document.body))
                    .clickOutsideToClose(true)
                    .title('')
                    .textContent($translate.instant('delete_warning'))
                    .ariaLabel('delete_warning')
                    .ok($translate.instant('confirm'))
                    .cancel($translate.instant('cancel'))
            ).then(function () {
                if (!vm.prevent_remove) {

                    vm.prevent_remove = true;
                    vm.promise = billingService.deleteInvoice(invoice.id).then(success);
                }
            });

            function success(data) {
                goBack();
            }
        }

        function goBack() {
            // window.history.back();
            $state.go('app.billing.invoice');
        }

        function SelectProcedure(data) {
            let invoice_detail = {description: $translate.instant('other_supply'), sub_details: []};

            _.forEach(data, function (procedure) {
                if (_.isNil(_.find(invoice_detail.sub_details, {code: procedure.code}))) {
                    invoice_detail.sub_details.push({
                        code: procedure.code || procedure.name,
                        description: procedure.name,
                        qte: 1,
                        price: procedure.price,
                        procedure_uid: procedure.uid,
                        discount: 0.0
                    });
                }
            });

            let other_detail = _.find(vm.invoice.invoice_details, {description: invoice_detail.description});
            if (!_.isNil(other_detail)) {
                let sub_detail_append = _.filter(invoice_detail.sub_details, function (sub_detail) {
                    if (_.isEmpty(_.filter(other_detail.sub_details, {code: sub_detail.code}))) return sub_detail;
                });
                other_detail.sub_details = other_detail.sub_details.concat(sub_detail_append);
            } else if (!_.isEmpty(invoice_detail.sub_details)) vm.invoice.invoice_details.push(invoice_detail);
        }

        function SelectVisitProcedure(line, ev) {
            $mdDialog.show(_.assign(require('billing/dialogs/procedure-search-dialog'), {
                targetEvent: ev,
                locals: {
                    search: {interdict_sale: {$ne: true}, is_deleted: {$ne: true}}
                }
            })).then(function selectLineCallBack(data) {
                refreshLines(data, line.sub_details);
            });

            function refreshLines(procedures, sub_details) {
                _.forEach(procedures, function (procedure) {
                    if (_.isNil(_.find(sub_details, {code: procedure.code}))) {
                        sub_details.push({
                            code: procedure.code || procedure.name,
                            description: procedure.name,
                            qte: 1,
                            price: procedure.price,
                            procedure_uid: procedure.uid,
                            discount: 0.0
                        });
                    }
                });
            }
        }

        function prePrint() {
            assignBeneficiaryModel();
            return billingService.saveInvoice(vm.invoice);
        }

        function SelectTreatmentPlan(ev) {
            $mdDialog.show(_.assign(require('billing/dialogs/dental-treatment-plan-search-dailog'), {
                targetEvent: ev,
                locals: {
                    beneficiary: vm.invoice.beneficiary,
                    beneficiary_type: vm.invoice['beneficiary_type']
                }
            })).then(function doneCallBack(data) {
                let InvoicedPlans = [];
                let noProceduresPlans = [];
                vm.processedPlans = 0;
                if (!_.isEmpty(data)) {
                    _.forEach(data, function (plan) {
                        loadPlanProcedure(plan, InvoicedPlans, data.length);
                    })
                }
            });
        }

        function loadPlanProcedure(plan, InvoicedPlans, dataLength) {
            billingService.getPlanNonInvoicedProcedure(plan.id, vm.invoice.id).then((data) => {
                handlePlanProcedures(plan, data, InvoicedPlans)
                vm.processedPlans += 1;
                if (InvoicedPlans.length > 0 && vm.processedPlans == dataLength) {
                    showNotification($translate.instant('treatment_plan_totally_invoiced') + '<br>' + _.join(InvoicedPlans, '<br>'));
                }
            });

            function handlePlanProcedures(plan, procedures, InvoicedPlans) {
                let plan_details = [];
                _.forEach(procedures, function (procedure) {
                    let desc = `${procedure.name} ${!_.isEmpty(procedure.teeth) ? '-' : ''} ${$translate.instant('dental_procedure_applied_from', procedure)}`;
                    let detail_idx = _.findIndex(plan_details, {code: procedure.code, description: desc});
                    if (detail_idx > -1 && procedure.price === plan_details[detail_idx].price) _.update(plan_details, `[${detail_idx}].qte`, (e) => {
                        return e + 1;
                    })
                    else {
                        plan_details.push({
                            code: procedure.code,
                            description: desc,
                            qte: procedure.qte || 1,
                            price: procedure.price,
                            discount: _.get(procedure, 'discount'),
                            procedure_uid: procedure.line_uid,
                            treatment_plan: {id: plan.id}
                        });
                    }
                });

                if (!_.isEmpty(plan_details)) refreshLinesByTreatmentPlan(plan, plan_details);
                else InvoicedPlans.push(`${plan['patient_fullname']} - ${plan.title}`);
            }

            function refreshLinesByTreatmentPlan(plan, plan_details) {
                let invoice_detail = {
                    description: `${plan['patient_fullname']} - ${plan.title}`,
                    sub_details: plan_details
                };
                let plan_detail = _.find(vm.invoice.invoice_details, function (item) {
                    return !_.chain(item.sub_details).find(function (sub_detail) {
                        return !_.isNil(sub_detail.treatment_plan) && sub_detail.treatment_plan.id == plan.id
                    }).isNil().value() || item.description == invoice_detail.description
                });
                if (!_.isNil(plan_detail)) {
                    _.forEach(invoice_detail.sub_details, function (detail) {
                        let detail_idx = _.findIndex(plan_detail.sub_details, {
                            code: detail.code,
                            description: detail.description
                        });
                        if (detail_idx == -1) {
                            plan_detail.sub_details.push(detail);
                        }
                    });
                } else {
                    vm.invoice.invoice_details.push(invoice_detail);
                }
            }
        }

        function selectCarePlan(ev) {
            $mdDialog.show(
                _.assign(CARE_PLAN_SEARCH_DIALOG, {
                    targetEvent: ev,
                    locals: {
                        beneficiary: vm.invoice.beneficiary,
                        beneficiary_type: vm.invoice['beneficiary_type'],
                        executed_sessions: {"$ne": []}
                    }
                })
            ).then(data => {
                if (!_.isEmpty(data)) {
                    const InvoicedPlans = [];
                    vm.processedPlans = 0;
                    _.forEach(data, plan => {
                        loadCarePlanProcedure(plan, InvoicedPlans, data.length);
                    });
                }
            }, _.noop);
        }

        function loadCarePlanProcedure(plan, InvoicedPlans, dataLength) {
            billingService.getCarePlanNonInvoicedProcedure(plan.id, vm.invoice.id).then((data) => {
                handleCarePlanProcedures(plan, data['available_procedures'], InvoicedPlans)
                vm.processedPlans += 1;
                if (InvoicedPlans.length > 0 && vm.processedPlans == dataLength) {
                    showNotification($translate.instant('care_plan_totally_invoiced') + '<br>' + _.join(InvoicedPlans, '<br>'));
                }
            });

            function handleCarePlanProcedures(plan, procedures, InvoicedPlans) {
                let plan_details = [];
                _.forEach(procedures, function (session_procedure) {
                    let procedure = _.chain(plan.executed_sessions)
                        .find(['procedure_uid', session_procedure.uid])
                        .get("cure.cure_procedures", [])
                        .find(['uid', session_procedure.uid])
                        .value();

                    let detail_idx = _.findIndex(plan_details, {
                        code: _.get(procedure, "code", ""),
                        description: _.get(procedure, "name", "")
                    });
                    if (detail_idx > -1 && _.get(procedure, "price", 0) === plan_details[detail_idx].price) {
                        _.update(plan_details, `[${detail_idx}].qte`, (e) => {
                            return e + 1;
                        });
                    } else {
                        plan_details.push({
                            code: _.get(procedure, "code", ""),
                            description: _.get(procedure, "name", ""),
                            qte: _.get(session_procedure, 'total_qte', 0) - _.get(session_procedure, 'invoiced_qte', 0),
                            price: _.get(procedure, "price", 0),
                            discount: _.get(procedure, 'discount', 0),
                            procedure_uid: _.get(session_procedure, 'uid'),
                            medical_care_plan: {id: plan.id}
                        });
                    }
                });
                if (!_.isEmpty(plan_details)) refreshByCarePlan(plan, plan_details);
                else InvoicedPlans.push(`${plan['patient_fullname'] || _.get(plan, 'patient.full_name')} - ${plan.title}`);
            }


            function refreshByCarePlan(plan, plan_details) {
                let invoice_detail = {
                    description: `${plan['patient_fullname']} - ${plan.title}`,
                    sub_details: plan_details
                };
                let plan_detail = _.find(vm.invoice.invoice_details, function (item) {
                    return !_.chain(item.sub_details).find(function (sub_detail) {
                        return !_.isNil(sub_detail.medical_care_plan) && sub_detail.medical_care_plan.id == plan.id
                    }).isNil().value() || item.description == invoice_detail.description
                });
                if (!_.isNil(plan_detail)) {
                    _.forEach(invoice_detail.sub_details, function (detail) {
                        let detail_idx = _.findIndex(plan_detail.sub_details, {
                            code: detail.code,
                            description: detail.description
                        });
                        if (detail_idx == -1) {
                            plan_detail.sub_details.push(detail);
                        }
                    });
                } else {
                    vm.invoice.invoice_details.push(invoice_detail);
                }
            }


        }

        function SelectDentalConsultation(ev) {
            $mdDialog.show(_.assign(require('../dialogs/dental-visit-search-dialog'), {
                targetEvent: ev,
                locals: {
                    beneficiary: vm.invoice.beneficiary,
                    beneficiary_type: vm.invoice['beneficiary_type']
                }
            })).then(function doneCallBack(data) {


                let InvoicedVisits = [];
                vm.processedDentals = 0;
                if (!_.isEmpty(data)) {
                    _.forEach(data, function (visit) {
                        loadDentalVisitProcedure(visit, InvoicedVisits, data.length);
                    })
                }
            });
        }

        function loadDentalVisitProcedure(visit, InvoicedVisits, dataLength) {
            billingService.getDentalNonInvoicedProcedure(visit.id, vm.invoice.id).then((data) => {
                handleDentalProcedures(visit, data, InvoicedVisits)
                vm.processedDentals += 1;
                if (visit.length > 0 && vm.processedDentals == dataLength) {
                    showNotification($translate.instant('dental_visit_totally_invoiced') + '<br>' + _.join(InvoicedVisits, '<br>'));
                }
            });

            function handleDentalProcedures(visit, procedures, InvoicedVisits) {
                // TO RECTIFY AFTER TOTAL PROCEDURE DISCOUNT(amine)
                let visit_details = [];
                const proceduresPrices = _.get(visit, 'procedures_prices', []); //ADDED

                _.forEach(procedures, function (procedure) {
                    let proceduresPricesItem = {};
                    if (!!_.get(procedure, "treatment_plan_id")) {
                        proceduresPricesItem = _.find(proceduresPrices, ["uid", _.get(procedure, "treatment_plan_uid")]); //EDITED
                    } else {
                        proceduresPricesItem = _.find(proceduresPrices, ["uid", _.get(procedure, "procedure_uid")]); //EDITED
                    }
                    //let proceduresPricesItem = _.find(proceduresPrices, ["uid", _.get(procedure, "procedure_uid")]); //EDITED
                    let desc = `${procedure.name} ${!_.isEmpty(procedure.teeth) ? '-' : ''} ${$translate.instant('dental_procedure_applied_from', procedure)}`;
                    let detail_idx = _.findIndex(visit_details, {code: procedure.code, description: desc});
                    let qte = procedure.qte || 1; //ADDED

                    if (detail_idx > -1 && proceduresPricesItem.price === visit_details[detail_idx].price) { //EDITED
                        _.update(visit_details, `[${detail_idx}]`, (e) => { //EDITED
                            e.qte += 1;
                            e.discount = (proceduresPricesItem.discount + global_discount) * e.qte;
                            return e;
                        });
                    } else {
                        visit_details.push({
                            code: procedure.code,
                            description: desc,
                            qte: qte,
                            price: _.get(proceduresPricesItem, "price", 0), //EDITED
                            discount: (_.get(proceduresPricesItem, 'discount', 0) + _.get(proceduresPricesItem, 'global_discount', 0)) * qte, //EDITED
                            procedure_uid: _.get(proceduresPricesItem, 'uid'), //EDITED
                            dental_consultation: {id: visit.id},
                            teeth_array: procedure.teeth
                        });
                    }
                });
                // TO RECTIFY AFTER TOTAL PROCEDURE DISCOUNT(amine)

                if (!_.isEmpty(visit_details)) refreshLinesByDentalVisit(visit, visit_details);
                else InvoicedVisits.push(`${_.get(visit, 'patient_fullname')} - ${_.get(visit, 'visit_date')}`);
            }

            function refreshLinesByDentalVisit(visit, visit_details) {
                let invoice_detail = {
                    description: `${_.get(visit, 'patient_fullname')} -${$translate.instant('visit_at')} ${_.get(visit, 'visit_date')}`,
                    sub_details: visit_details
                };
                let dental_detail = _.find(vm.invoice.invoice_details, function (item) {
                    return !_.chain(item.sub_details).find(function (sub_detail) {
                        return !_.isNil(sub_detail.dental_consultation) && sub_detail.dental_consultation.id == visit.id
                    }).isNil().value() || item.description == invoice_detail.description
                });
                if (!_.isNil(dental_detail)) {
                    _.forEach(invoice_detail.sub_details, function (detail) {
                        let detail_idx = _.findIndex(dental_detail.sub_details, {
                            code: detail.code,
                            description: detail.description
                        });
                        if (detail_idx == -1) {
                            dental_detail.sub_details.push(detail);
                        }
                    });
                } else {
                    vm.invoice.invoice_details.push(invoice_detail);
                }
            }
        }

    }
})();
