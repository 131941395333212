/**
 * Created by amine on 21/04/2022.
 */
(function () {
    'use strict';

    const {AsyncSubject} = require("rxjs");

    class ExamFormCtrl {
        constructor($scope, examService, $transition$, visitService, system, $q, observationService, formsService, moment, editorManagerService) {
            this.scope = $scope;
            this.$transition$ = $transition$;
            this.examService = examService;
            this.visitService = visitService;
            this.q = $q;
            this.observationService = observationService;
            this.formsService = formsService;
            this.moment = moment;
            this.editorManagerService = editorManagerService;

            this.currentPatient = _.get($transition$.params('to'), 'pId', null);
            this.currentVisit = _.get($transition$.params('to'), 'visitId', null);
            this.template = _.get($transition$.params('to'), 'template', null);
            this.examId = _.get($transition$.params('to'), 'examId', null);
            this.observationId = _.get($transition$.params('to'), 'observationId');
            this.model = _.get($transition$.params('to'), 'model', null);
            this.copy = _.get($transition$.params('to'), 'copy', null);
            this.globalContext = _.get($transition$.params('to'), 'nv', null);

            this.dateFormat = system['date_format'].js
            this.timeFormat = system['time_format'].js

            this.observationContext = false;
            this.customMenus = false;
            this.loadingPromise = null;
            this.visitContext = !!this.currentVisit;
            this.editorName = _.uniqueId('exam-editor-');
            this.editorConfigKey = "exam_editor";
            this.editorOpts = {patient: this.currentPatient};
            this.exam = {};

            this.addAsModel = (exam, editorName, ev) => examService.addExamModel(exam, editorName, ev);
            // this.prePrint = this.examService.prePrint(quit => this.saveExam(quit), );
        }

        static get $inject() {
            return [
                "$scope", "examService", "$transition$", "visitService", "system", "$q", "observationService",
                "formsService", "moment", "editorManagerService"
            ];
        }

        $onInit() {
            this.examService.sideNaveSubject.next(true);
            this.examService.selectedTemplate.next(this.template);
            this.examService.formSubject.next(true);

            this.contentSubscription = this.examService.contentSubject
                .subscribe(model => {
                    if (!this.exam.content) this.exam.content = "";

                    if (model.use) {
                        if (!!this.examId) {
                            this.exam = {
                                content: model.content
                            }
                            this.examService.newState(model.template, model, false);
                        } else {
                            this.exam.content = model.content;
                        }

                        return false;
                    }

                    this.exam.content = `${this.exam.content}${model.content}`;
                });

            this.examSubscription = this.examService.examSubject
                .subscribe(status => {
                    if (_.has(status, "added.id")) {
                        this.examId = _.get(status, "added.id", null)
                    }

                    if (_.has(status, "loaded.id")) {
                        this.examId = _.get(status, "loaded.id", null);
                        this.loadingPromise = this.loadExam()
                    }

                    if (_.get(status, "deleted", null) === this.examId) {
                        this.listState();
                    }
                });

            if (_.isNil(this.examId)) {
                let promises = [
                    this.visitService.getVisit(this.currentVisit),
                    this.examService.getExamTemplate(this.template)
                ]

                if (!!this.model) {
                    this.examService.currentModelSubject.next({id: this.model, type: "use", model: true});
                    promises.push(this.examService.getExamModel(this.model));
                }
                if (!!this.copy) {
                    this.examService.currentModelSubject.next({id: this.copy, type: "duplicate", model: false});
                    promises.push(this.examService.getExam(this.copy));
                }

                this.loadingPromise = this.q.all(promises)
                    .then(data => {
                        this.exam = {
                            visit: _.pick(data[0], 'id'),
                            template: data[1].id,
                            form_body: _.chain(data[1]).get("form_template", null).cloneDeep().value(),
                            exam_date: this.moment().format(this.dateFormat), //_.get(data[0], 'visit_date'),
                            start_time: this.moment().format(this.timeFormat),
                            content: _.isNil(data[2]) ? "" : data[2].content
                        }

                        this.customMenu();
                    }, () => {
                        if (this.visitContext) this.listState()
                    });
            } else {
                this.loadExam()
            }

            if (!_.isNil(this.observationId)) this.observationContext = true;
        }

        $onDestroy() {
            if (this.contentSubscription) this.contentSubscription.unsubscribe();
            if (this.examSubscription) this.examSubscription.unsubscribe();
        }

        loadExam() {
            return this.examService.getExam(this.examId)
                .then(data => {
                    this.exam = data;
                    this.examService.selectedTemplate.next(this.exam.template);
                    this.examService.currentModelSubject.next({id: this.exam.id, type: "edit", model: false});

                    if (this.exam.template.has_form && !this.exam.form_body) this.exam.form_body = _.cloneDeep(this.exam.template.form_template);

                    this.customMenu();
                }, () => {
                    if (this.visitContext) this.listState()
                });
        }

        saveExam(quit) {
            const afterSave = (data, supQuery = {}) => {
                if (quit) return this.goBack();

                this.exam.id = data.id;
                this.examService.editState(data, data.template, false, supQuery);
            }

            return this.promise = this.examService
                .handleSaving(this.exam, this.currentPatient, this.currentVisit)
                .then(data => {
                    if (this.observationContext) {
                        this.observationService.saveExternalReportEntry(data.id, this.observationId)
                            .then(observationId => {
                                this.observationId = observationId;
                                afterSave(data, observationId ? {observationId: observationId} : {})
                            })
                    } else {
                        afterSave(data);
                    }
                });
        }

        removeExam(exam, patient, ev) {
            this.examService.removeExam(exam, patient, ev).then(() => this.listState(), _.noop);
        }

        prePrint(quit) {
            let deferred = this.q.defer();

            this.editorManagerService
                .getTinymce(this.editorName)
                .compileContent()
                .then(() => this.saveExam(quit).then(() => deferred.resolve(false)));

            return deferred.promise;
        }

        changeEnd() {
            // const start_time = this.moment(this.exam.start_time, this.timeFormat);
            const end_time = this.moment(this.exam.end_time, this.timeFormat);

            if (end_time.isValid()) {
                this.exam.has_duration = true;
            } else {
                this.exam.end = null;
                this.exam.has_duration = false;
            }
        }

        changeDuration() {
            // const start_time = this.moment(vm.exam.start_time, this.timeFormat);
            const end_time = this.moment(this.exam.end_time, this.timeFormat);

            if (this.exam.has_duration && !end_time.isValid()) {
                this.exam.end_time = this.moment().format(this.timeFormat);
            } else {
                this.exam.end_time = null;
            }
        }

        goBack() {
            if (this.observationContext) this.observationService.returnTo(this.observationId);
            else this.listState();
        }

        listState() {
            setTimeout(() => {
                if (_.isNil(this.globalContext)) this.examService.listState();
                else this.examService.globalListState();
            }, 300);
        }

        onDestroy() {
            this.examService.self.formSubject.next(false);
            this.contentSubscription.unsubscribe();
        }

        customMenu() {
            const excludedTypes = [
                "title",
                "checkbox-table",
                "table-layout",
                "paragraph",
                "image"
            ]

            this.customMenus = new AsyncSubject();
            this.customMenus.next([{
                title: "exam_body",
                dynamic: true,
                show: !!this.exam.form_body && !_.isEmpty(this.exam.form_body),
                fetch: () => {
                    let items = _.reduce(this.exam.form_body.blocks, (acc, block) => {
                        return _.concat(acc,
                            _.chain(block.fields)
                                .filter(item => !_.includes(excludedTypes, item.type))
                                .map(field => {
                                    return {
                                        title: !!block.label ? `${block.label} : ${field.label}` : field.label,
                                        noTranslate: true,
                                        refresh: true,
                                        value: () => {
                                            return this.formsService.getValueForEditor(field);
                                        },
                                        key: `${block.name}::${field.name}`
                                    }
                                })
                                .value()
                        )
                    }, []);

                    return this.q.when(items);
                }
            }]);
            this.customMenus.complete();
        }
    }

    module.exports = ExamFormCtrl;

})();
