(function () {

    'use strict';

    const PERIO_BUCCAL_LEFT_CONFIG = require('../json/perio-buccal-left-config.json');
    const PERIO_BUCCAL_RIGHT_CONFIG = require('../json/perio-buccal-right-config.json');

    const PERIO_LINGUAL_LEFT_CONFIG = require('../json/perio-lingual-left-config.json');
    const PERIO_LINGUAL_RIGHT_CONFIG = require('../json/perio-lingual-right-config.json');

    const PERIO_LINGUAL_BOTTOM_LEFT_CONFIG = require('../json/perio-lingual-bottom-left-config.json');
    const PERIO_LINGUAL_BOTTOM_RIGHT_CONFIG = require('../json/perio-lingual-bottom-right-config.json');

    const PERIO_BUCCAL_BOTTOM_LEFT_CONFIG = require('../json/perio-buccal-bottom-left-config.json');
    const PERIO_BUCCAL_BOTTOM_RIGHT_CONFIG = require('../json/perio-buccal-bottom-right-config.json');

    class PeriodontalDetailCtrl {
        constructor(PeriodontalService, visitService, $transition$, $state, $element, $scope) {
            this.state = $state;
            this.scope = $scope;
            this.element = $element;
            this.visitService = visitService;
            this.service = PeriodontalService;

            this.currentExam = _.get($transition$.params('to'), 'exam');
            this.currentVisit = _.get($transition$.params('to'), 'visitId');

            this.change = _.mnDelay(() => this.changeCall(), 750);
        }

        static get $inject() {
            return ["PeriodontalService", "visitService", "$transition$", "$state", "$element", "$scope"];
        }

        $onInit() {
            this.service.activeItemSubject.next(this.currentExam);
            this.currentPatientSubscription = this.visitService.currentPatientSubject.subscribe(data => this.patient = data);
            this.promise = this.service.getPeriodontalExamDetail(this.currentExam, this.currentVisit)
                .then(data => {
                    this.exam = data;
                    this.statistics = this.recalculateStatistics();

                    this.config_1 = PERIO_BUCCAL_LEFT_CONFIG;
                    this.config_2 = PERIO_BUCCAL_RIGHT_CONFIG;
                    this.config_3 = PERIO_LINGUAL_LEFT_CONFIG;
                    this.config_4 = PERIO_LINGUAL_RIGHT_CONFIG;

                    this.config_5 = PERIO_LINGUAL_BOTTOM_LEFT_CONFIG;
                    this.config_6 = PERIO_LINGUAL_BOTTOM_RIGHT_CONFIG;
                    this.config_7 = PERIO_BUCCAL_BOTTOM_LEFT_CONFIG;
                    this.config_8 = PERIO_BUCCAL_BOTTOM_RIGHT_CONFIG;
                });

            this.scope.$on("$destroy", () => this.onDestroy())
        }

        onDestroy() {
            if (this.currentPatientSubscription) this.currentPatientSubscription.unsubscribe();
        }

        changeCall() {
            this.statistics = this.recalculateStatistics();
            this.service.handlePeriodontalExam(this.exam)
                .then(data => {
                    if (!this.exam.id) {
                        this.exam.id = data.id;
                        this.currentExam = data.id;
                        this.state.go("app.visit.periodontal.detail", {exam: data.id}, {location: 'replace'});
                    }

                    this.service.newItemSubject.next(_.pick(data, ['id', 'exam_date', 'visit_id']));
                    this.service.activeItemSubject.next(this.currentExam);
                });
        }

        recalculateStatistics() {
            return _.chain(this.exam.body)
                .reduce((accu, tooth) => {
                    return {
                        margin: accu.margin + this.toothItemCalc(tooth, 'gingival_margin'),
                        plaque: accu.plaque + this.toothItemCalc(tooth, 'plaque'),
                        bleeding: accu.bleeding + this.toothItemCalc(tooth, 'bleeding'),
                        probingDepth: accu.probingDepth + this.toothItemCalc(tooth, 'probing_depth'),
                    }
                }, {probingDepth: 0, margin: 0, plaque: 0, bleeding: 0})
                .thru(value => {
                    return {
                        margin: (value.margin / 32).toFixed(2),
                        plaque: ((value.plaque / 32) * 100).toFixed(2),
                        bleeding: ((value.bleeding / 32) * 100).toFixed(2),
                        probingDepth: (value.probingDepth / 32).toFixed(2),
                    }
                })
                .value();
        }

        toothItemCalc(tooth, item) {
            return _.chain(tooth).get(item, []).values().sumBy(
                v => _.isBoolean(v) ? (v ? 1 : 0) : v
            ).divide(3).value();
        }

        printExam() {
            this.element.print();
        }
    }

    module.exports = PeriodontalDetailCtrl;

})();
