(function () {
    'use strict';

    // EX: (old:RegEx,new:String): {base:"default","rules":[{old:"",new: ""}]}
    /* Employé =>
    "key": "switch_lang",
    "value": {
        "base": "default",
        "rules": [
            {
                "old":"(^|\\s)Le Patient",
                "new": "$1L'Employé"
            },
            {
                "old":"(^|\\s)Le patient",
                "new": "$1L'employé'"
            },
            {
                "old":"(^|\\s)le patient",
                "new": "$1l'employé'"
            },
            {
                "old":"Du Patient",
                "new": "De l'Employé"
            },
            {
                "old":"Du patient",
                "new": "De l'employé"
            },
            {
                "old":"du patient",
                "new": "de l'employé"
            },
            {
                "old":"Patient",
                "new": "Employé"
            },
            {
                "old":"Patients",
                "new": "Employés"
            },
            {
                "old":"patients",
                "new": "employés"
            },
            {
                "old":"(^|\\s)employé",
                "new": "$1employé"
            }
        ]
    },
    */

    const DEFAULT_LANG = (ctx => {
        let keys = ctx.keys();
        let values = keys.map(ctx);
        return keys.reduce((o, k, i) => _.assign(o, values[i]), {});
    })(require.context('../../', true, /^.*lang.json$/));

    const MEMBERS_LANG = _.mapValues(DEFAULT_LANG, value => handleMembersLang(value));
    const PLAYERS_LANG = _.mapValues(DEFAULT_LANG, value => handlePlayersLang(value));
    const FILES_LANG = _.mapValues(DEFAULT_LANG, value => handleFilesLang(value));

    const SUPPORTED_TRANSLATIONS = {
        default: DEFAULT_LANG,
        members: MEMBERS_LANG,
        players: PLAYERS_LANG,
        files: FILES_LANG
    }

    function handleMembersLang(value) {
        if (_.isString(value)) return _.chain(value)
            .replace(/(^|\s)Le Patient/g, 'L\'Adhérent')
            .replace(/(^|\s)Le patient/g, 'L\'adhérent')
            .replace(/(^|\s)le patient/g, 'l\'adhérent')
            .replace(/Du Patient/g, 'De L\'Adhérent')
            .replace(/Du patient/g, 'De l\'adhérent')
            .replace(/du patient/g, 'de l\'adhérent')
            .replace(/Patient/g, 'Adhérent')
            .replace(/Patients/g, 'Adhérents')
            .replace(/patients/g, 'adhérents')
            .replace(/(^|\s)patient/g, '$1adhérent')
            .value();
        else return _.mapValues(value, sub => handleMembersLang(sub));
    }

    function handlePlayersLang(value) {
        if (_.isString(value)) {
            return _.chain(value)
                .replace(/(^|\s)Le Patient/g, '$1Le Joueur')
                .replace(/(^|\s)Le patient/g, '$1Le joueur')
                .replace(/(^|\s)le patient/g, '$1le joueur')
                .replace(/Du Patient/g, 'Du Joueur')
                .replace(/Du patient/g, 'Du joueur')
                .replace(/du patient/g, 'du joueur')
                .replace(/Patient/g, 'Joueur')
                .replace(/Patients/g, 'Joueurs')
                .replace(/patients/g, 'joueurs')
                .replace(/(^|\s)patient/g, '$1joueur')
                .value();
        } else return _.mapValues(value, sub => handlePlayersLang(sub));
    }

    function handleFilesLang(value) {
        if (_.isString(value)) {
            return _.chain(value)
                .replace(/(^|\s)Le Patient/g, '$1Le Dossier')
                .replace(/(^|\s)Le patient/g, '$1Le dossier')
                .replace(/(^|\s)le patient/g, '$1le dossier')
                .replace(/Du Patient/g, 'Du Dossier')
                .replace(/Du patient/g, 'Du dossier')
                .replace(/du patient/g, 'du dossier')
                .replace(/Patient/g, 'Dossier')
                .replace(/Patients/g, 'Dossiers')
                .replace(/patients/g, 'dossiers')
                .replace(/(^|\s)patient/g, '$1dossier')
                .value();
        } else return _.mapValues(value, sub => handleFilesLang(sub));
    }

    function handleAdditionalRules(translateValues, rules) {
        return _.mapValues(translateValues, value => {
            if (_.isString(value)) {
                return _.reduce(rules, (translateValue, rule) => {
                    return _.replace(translateValue, new RegExp(rule.old, "g"), rule.new);
                }, value);
            } else {
                return handleAdditionalRules(value, rules);
            }
        });
    }

    class TranslationsConfig {
        constructor(translateProvider, system) {
            const selectLang = _.get(system, "switch_lang", "default");

            if (!!selectLang.base && !!selectLang.rules) {
                const preferredTranslation = selectLang.base && _.includes(_.keys(SUPPORTED_TRANSLATIONS), selectLang.base) ? selectLang.base : "default";
                const translation = handleAdditionalRules(SUPPORTED_TRANSLATIONS[preferredTranslation], selectLang.rules);

                translateProvider.translations("lang", translation);
            } else {
                const preferredTranslation = selectLang && _.includes(_.keys(SUPPORTED_TRANSLATIONS), selectLang) ? selectLang : "default";
                translateProvider.translations("lang", SUPPORTED_TRANSLATIONS[preferredTranslation]);
            }

            translateProvider.preferredLanguage("lang");
            translateProvider.useSanitizeValueStrategy('escaped');
        }

        static create() {
            return new TranslationsConfig(...arguments);
        }
    }

    TranslationsConfig.create.$inject = ['$translateProvider', 'system'];

    module.exports = TranslationsConfig.create;

})();
