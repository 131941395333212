/**
 * Edited by Amine 22/10/2019.
 */

(function () {

    'use strict';

    class CarePlanSearchDialogCtrl {
        constructor($mdDialog, medicalCareService, $translate, system) {
            this.dialog = $mdDialog;
            this.medicalCareService = medicalCareService;
            this.dateFormat = system['date_format'].js;

            this.selected = [];
            this.query = {
                limit: 10,
                page: 1,
                order: "-creation_date"
            };

            this.paginationLabel = {
                page: $translate['instant']('page'),
                rowsPerPage: $translate['instant']('rowsPerPage'),
                of: $translate['instant']('of')
            };

            this.options = [20, 15, 10, 5];
            this.plans = [];
            this.consultTotal = 0;
            this.onPaginate = (page, limit) => this.#_onPaginate(page, limit);
            this.onReorder = order => this.#_onReorder(order);
        }

        static get $inject() {
            return ["$mdDialog", "medicalCareService", "$translate", "system"];
        }

        $onInit() {
            this.beneficiary = this.beneficiary || null;
            this.beneficiary_type = this.beneficiary_type || "";

            if (this.beneficiary_type !== "PATIENT") {
                this.plans = [];
            } else {
                const patient = _.get(this.beneficiary, "id");
                if (!!patient) this.promise = this.medicalCareService.getByPatient(patient, 'plan-billing')
                    .then(data => {
                        this.plans = data;
                        this.consultTotal = data.length;
                    })
            }


            // this.promise = this.paymentService
            //     .getDentalConsultation(_.assign(this.query, {patient: _.get(this.patient, "id"), only_patient: true}))
            //     .then(data => {
            //         this.selectedConsult = null;
            //         this.consultTotal = data.length;
            //         this.consults = data.list;
            //         if (this.consultTotal < ((this.query.page - 1) * this.query.limit)) {
            //             this.query.page = 1;
            //         }
            //     });
        }

        getData() {
            const order = this.query.order[0] === '-' ? 'desc' : 'asc';
            const min = (this.query.page - 1) * this.query.limit, max = (this.query.page) * this.query.limit;

            return _.chain(this.plans)
                .orderBy(plan => moment(plan.visit_date, this.dateFormat).valueOf(), order)
                .slice(min, max)
                .value();
        }

        #_onPaginate(page, limit) {
            this.query = _.assign(this.query, {page: page, limit: limit});
        }

        validateSelection() {
            this.dialog.hide(this.selected);
        }

        #_onReorder(order) {
            this.query = _.assign(this.query, {order: order});
        }

        cancel() {
            this.dialog.cancel();
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        controller: CarePlanSearchDialogCtrl,
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        template: require("./care-plan-search-dialog.tpl.html"),
    };


})();
