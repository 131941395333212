(function () {

    'use strict';

    const generateId = require('shared/utils/unique-id');

    function createHttpBackend($browser, createXhr, $browserDefer, callbacks, rawDocument, worker, originalBackend) {
        let callbacksObj = {};

        worker.onmessage = (ev) => {
            let callback = _.get(callbacksObj, ev.data.id, null);

            if (!callback) return;

            _.unset(callbacksObj, ev.data.id);

            switch (ev.data.cmd) {
                case 'complete':
                    completeRequest(callback, ev.data.kwargs[0], ev.data.kwargs[1], ev.data.kwargs[2], ev.data.kwargs[3], ev.data.kwargs[4]);
                    break;
                case 'requestError':
                    requestError(callback);
                    break;
                case 'requestAborted':
                    requestAborted(callback);
                    break;
                case 'requestTimeout':
                    requestTimeout(callback);
                    break;
            }
        }

        function requestError(callback) {
            // The response is always empty
            // See https://xhr.spec.whatwg.org/#request-error-steps and https://fetch.spec.whatwg.org/#concept-network-error
            completeRequest(callback, -1, null, null, '', 'error');
        }

        function requestAborted(callback) {
            completeRequest(callback, -1, null, null, '', 'abort');
        }

        function requestTimeout(callback) {
            // The response is always empty
            // See https://xhr.spec.whatwg.org/#request-error-steps and https://fetch.spec.whatwg.org/#concept-network-error
            completeRequest(callback, -1, null, null, '', 'timeout');
        }

        function completeRequest(callback, status, response, headersString, statusText, xhrStatus) {
            callback(status, response, headersString, statusText, xhrStatus);
        }

        return function (method, url, post, callback, headers, timeout, withCredentials, responseType, eventHandlers, uploadEventHandlers) {
            if (_.lowerCase(method) === 'jsonp' || post instanceof FormData) {
                originalBackend(
                    method, url, post, callback, headers, timeout, withCredentials, responseType, eventHandlers, uploadEventHandlers
                )
            } else {
                //console.log($browser.url());
                if(!url) {
                    return false;
                }

                let id = generateId();
                let args = {
                    id, url, post, method, headers, responseType, withCredentials
                };

                callbacksObj[id] = callback;
                worker.postMessage(args);
            }
        };
    }

    exports.createHttpBackend = createHttpBackend;

})();
