(function () {

    'use strict';

    const {BehaviorSubject} = require("rxjs");
const DOCUMENT_TYPES = {
        "visit": {'color': '#d717ec'},
        "delivery_form": {'color': '#17ec3a'},
        "treatment_plan": {'color': '#1ca0fd'},
        "medical_care_plan": {'color': '#ff5f20'},
        "dental_consultation": {'color': '#5fcddc'},
        "contract_instance": {'color': '#de1669'}}


    class BillingWorkflowService {
        constructor($q, $http, $mdDialog, mnWebSocket, moment, $translate, system, $sce) {
            this.q = $q;
            this.$sce = $sce;
            this.http = $http;
            this.dialog = $mdDialog;
            this.moment = moment;
            this.ws = mnWebSocket;
            this.translate = $translate;
            this.dateFormat = system['date_format'].js;
            this.timeFormat = system['time_format'].js;
            this.dateTimeFormat = system['date_format'].naive;

            this.columnConfiguration = [
                {
                    key: "created_date",
                    order_by: "created_at",
                    label: "created_at",
                    classes: "datetime-column",
                    getContent: item => {
                        if (!item['created_at']) return "<span></span>";

                        return `<span>${item['created_at']}</span>`;
                    },
                    "type": "date",
                    "is_searchable": true,
                    "is_global_searchable": true
                },
                {
                    key: "patient_full_name",
                    order_by: "patient.full_name",
                    label: "patient",
                    getContent: item => {
                        if (!item['patient']) return "<span></span>";

                        return `<div layout="row">
                                <strong>${item['patient']['full_name']}</strong>
                                                            
                            </div>`;
                    },
                    "type": "text",
                    "is_searchable": true,
                    "is_global_searchable": true,
                    "is_ref": true,
                    "model": "Patient",
                    "module": "patient",
                    "filter_fields": [
                        {
                            "key": "first_name",
                            "order_by": "first_name",
                            "label": "first_name",
                            "type": "text",
                            "is_searchable": true,
                            "is_global_searchable": true,
                            "model": "Patient",
                            "module": "patient",
                            "is_ref": true
                        },
                        {
                            "key": "last_name",
                            "order_by": "last_name",
                            "label": "last_name",
                            "type": "text",
                            "is_searchable": true,
                            "is_global_searchable": true,
                            "model": "Patient",
                            "module": "patient",
                            "is_ref": true
                        }
                    ]
                },
                {
                    key: "organization",
                    order_by: "organization.name",
                    label: "insurance",
                    getContent: item => {
                        if (!item['organization']) return "<span></span>";

                        return `<div layout="row">
                                <strong>${item['organization']['name']}</strong>
                                                            
                            </div>`;
                    },
                    "type": "text",
                    "is_searchable": true,
                    "is_global_searchable": true,
                    "is_ref": true,
                    "model": "Organization",
                    "module": "shared.insurance"
                },
                {
                    key: "cls",
                    order_by: "cls",
                    label: "mvt_source",
                    classes: "text-center   small-column",
                    getContent: item => {
                        if (!item['cls']) return "<span></span>";



                           return `<span style="background: ${DOCUMENT_TYPES[item['cls']] ? DOCUMENT_TYPES[item['cls']]['color'] : ''}"
                                class="mn-badge">
                               ${this.translate.instant(`billing_workflow_${item['cls']}`)}
                                </span>`;
                    },
                    "type": "text",
                    "is_searchable": true,
                    "is_global_searchable": true
                },
                {
                    key: "number",
                    order_by: "number",
                    label: "doc_number",
                    classes: "text-center",
                    getContent: item => {
                        if (!item['number']) return "<span></span>";

                        return `<span>${item['number']}</span>`;
                    },
                    "type": "text",
                    "is_searchable": true,
                    "is_global_searchable": true
                },
                {
                    key: "fully_billed",
                    order_by: "fully_billed",
                    label: "status",
                    classes: "text-center",
                    getContent: item => {
                        // if (!item['fully_billed']) return "<span></span>";
                        return `
                            <md-icon class="mdi mdi-file-document"
                                         style="color: ${item['fully_billed'] ? 'green' : 'red'}; margin:unset">
                            <md-tooltip md-direction="bottom" md-delay="150">
                                <span>${item['fully_billed'] ? this.translate.instant('fully_billed') :  this.translate.instant('not_fully_billed') } </span>
                            </md-tooltip>
                        `;
                        // return `<span style="background:  ${item['fully_billed'] ? 'green' : 'red'}"
                        //         class="mn-badge">
                        //         ${ item['fully_billed']}
                        //         </span>`;
                    },
                    "type": "text",
                    "is_searchable": true,
                    "is_global_searchable": false,
                    "choices": ["TRUE", "FALSE"]
                },
            ];
        }

        static get $inject() {
            return ["$q", "$http", "$mdDialog", "mnWebSocket", "moment", "$translate", "system", "$sce"];
        }

        getColumnConfiguration() {
            return _.cloneDeep(this.columnConfiguration);
        }
        getBillingWorkflowItems(query){
            return this.ws.call("billing.BillingWorkflow.get_list", {query});
        }
    }

    module.exports = BillingWorkflowService;

})();
