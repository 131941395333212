/**
 * Created by amine on 25/04/2022.
 */
(function () {

    'use strict';

    class ExamModelFormCtrl {
        constructor(examService, $transition$, $scope) {
            this.examService = examService;
            this.scope = $scope;

            this.modelId = _.get($transition$.params('to'), 'modelId', null);
            this.copy = _.get($transition$.params('to'), 'copy', null);

            this.modelsSubscription = null;
            this.editorName = _.uniqueId('exam-editor-');
            this.editorConfigKey = "exam_editor";
            this.editorOpts = {};
        }

        static get $inject() {
            return ["examService", "$transition$", "$scope"];
        }

        $onInit() {
            this.examService.sideNaveSubject.next(true);

            if (_.isNil(this.modelId)) {
                this.examService.getExamModel(this.copy)
                    .then(data => {
                        this.model = {
                            title: data.title + " (COPY)",
                            content: data.content,
                            template: data.template
                        }
                        this.examService.currentModelSubject.next({id: data.id, type: 'duplicate', model: true});
                        this.examService.selectedTemplate.next(this.model.template);
                    }, () => this.examService.listState());
            } else {
                this.examService.getExamModel(this.modelId)
                    .then(data => {
                        this.model = data;
                        this.examService.currentModelSubject.next({id: this.model.id, type: "edit", model: true});
                        this.examService.selectedTemplate.next(this.model.template);
                    }, () => this.examService.listState());
                this.modelsSubscription = this.examService.examModelSubject
                    .subscribe(status => {
                        if (_.has(status, "deleted")) {
                            this.examService.listState();
                        }
                    });
            }

            this.scope.$on("$destroy", () => this.onDestroy())
        }

        onDestroy() {
            if (!!this.modelsSubscription) this.modelsSubscription.unsubscribe();
        }

        saveModel(quit) {
            return this.examService.saveExamModel(this.model)
                .then(data => {
                    if (_.isNil(this.model.id)) {
                        this.model.id = data.id;
                        this.examService.currentModelSubject.next({id: data.id, type: 'edit', model: true});

                        if (!quit) this.examService.editModelState(data);
                    }

                    if (quit) this.examService.listState();
                });
        }

    }

    module.exports = ExamModelFormCtrl;

})();
