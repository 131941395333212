/**
 * edited by amine on 21/04/2022.
 */
(function () {

    'use strict';

    class ExamViewCtrl {
        constructor(examService, $transition$, $sce) {
            this.examService = examService;
            this.sce = $sce;

            this.currentPatient = _.get($transition$.params('to'), 'pId', null);
            this.visitContext = !_.isNil(this.currentPatient);
            this.examId = _.get($transition$.params('to'), 'examId', null);
            this.globalContext = _.get($transition$.params('to'), 'nv', null);

            this.editExam = exam => examService.editExam(exam);
            this.duplicateExam = exam => examService.duplicateExam(exam);
            this.removeExam = (exam, patient, $event) => {
                examService.removeExam(exam, patient, $event).then(() => this.listState(), _.noop);
            };

        }

        static get $inject() {
            return ["examService", "$transition$", "$sce"];
        }

        $onInit() {
            this.examService.getExam(this.examId)
                .then(data => {
                    this.exam = data;
                    this.exam.trustedContent = this.sce.trustAsHtml(this.exam.content);

                    this.examService.selectedTemplate.next(this.exam.template);
                }, () => {
                    if (this.visitContext) this.examService.listState();
                });
        }

        listState() {
            if (this.globalContext) {
                this.examService.globalListState();
            } else {
                this.examService.listState();
            }
        }

    }

    module.exports = ExamViewCtrl;

})();
