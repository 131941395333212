/**
 * Created by Amine on 16/08/2016.
 */
(function () {

    'use strict';

    module.exports = certificateService;

    const {Subject} = require("rxjs");
    const VERIFICATION_DIALOG = require("auth/dialogs/auth-verification-dialog");

    certificateService.$inject = ["$q", "$http", "$state", "$translate", "mnWebSocket", "$mdDialog"];

    function certificateService($q, $http, $state, $translate, mnWebSocket, $mdDialog) {
        let self = this;

        self.itemDeleted = new Subject();
        self.itemEdited = new Subject();
        self.itemDuplicated = new Subject();
        self.itemActive = new Subject();
        self.modelAdded = new Subject();
        self.medicalAdded = new Subject();

        self.getVisitCertificatesList = getVisitCertificatesList;
        self.getMedicalCertificates = getMedicalCertificates;
        self.getMedicalCertificateList = getMedicalCertificateList;
        self.getModelCertificateList = getModelCertificateList;
        self.getMedicalCertificate = getMedicalCertificate;
        self.getModelCertificate = getModelCertificate;
        self.saveMedicalCertificate = saveMedicalCertificate;
        self.saveModelCertificate = saveModelCertificate;
        self.getCategories = getCategories;
        self.getDefaultCategory = getDefaultCategory;
        self.getCategory = getCategory;
        self.removeCategory = removeCategory;
        self.saveCategory = saveCategory;
        self.newState = newState;
        self.listState = listState;
        self.useState = useState;
        self.editState = editState;
        self.duplicateState = duplicateState;
        self.deleteItem = deleteItem;

        self.constructedTitle = constructedTitle;

        self.sign = sign;
        self.unsign = unsign;


        function getVisitCertificatesList(visit) {
            return mnWebSocket.call('certificate.MedicalCertificate.full_list', {visit: _.isObject(visit) ? visit.id : visit})
        }

        function getMedicalCertificateList(patient) {
            let deferred = $q.defer();

            $http.get("/api/certificate-list/?patient=" + patient + "&flag=1")
                .then(success, error);

            function success(result) {
                deferred.resolve(result.data);
            }

            function error(data) {
                deferred.reject(data);
            }

            return deferred.promise;
        }

        function getModelCertificateList() {
            let deferred = $q.defer();

            $http.get("/api/model-certificate-list/")
                .then(success, error);

            function success(result) {
                deferred.resolve(result.data);
            }

            function error(data) {
                deferred.reject(data);
            }

            return deferred.promise;
        }

        function getMedicalCertificates(patient) {
            let deferred = $q.defer();

            $http.get("/api/certificate/?patient=" + patient + "&flag=1")
                .then(success, error);

            function success(result) {
                deferred.resolve(result.data);
            }

            function error(data) {
                deferred.reject(data);
            }

            return deferred.promise;
        }

        function getMedicalCertificate(pk) {
            let deferred = $q.defer();

            $http.get("/api/certificate/" + pk + "/")
                .then(success, error)

            function success(result) {
                deferred.resolve(result.data);
            }

            function error(data) {
                deferred.reject(data);
            }

            return deferred.promise;
        }

        function getModelCertificate(pk) {
            let deferred = $q.defer();

            $http.get("/api/model-certificate/" + pk + "/")
                .then(success, error);

            function success(result) {
                deferred.resolve(result.data);
            }

            function error(data) {
                deferred.reject(data);
            }

            return deferred.promise;
        }

        function getCategories() {
            let deferred = $q.defer();

            $http.get("/api/certificate-category/")
                .then(success, error);

            function success(result) {
                deferred.resolve(result.data);
            }

            function error(data) {
                deferred.reject(data);
            }

            return deferred.promise;
        }

        function getCategory(pk) {
            let deferred = $q.defer();

            $http.get("/api/certificate-category/" + pk + "/")
                .then(success, error);

            function success(result) {
                deferred.resolve(result.data);
            }

            function error(data) {
                deferred.reject(data);
            }

            return deferred.promise;
        }

        function removeCategory(pk) {
            let deferred = $q.defer();

            $http.delete("/api/certificate-category/" + pk + "/")
                .then(success, error);

            function success(result) {
                deferred.resolve(result.data);
            }

            function error(data) {
                deferred.reject(data);
            }

            return deferred.promise;
        }

        function getDefaultCategory() {
            return getCategory(1);
        }

        function save(url, certificate) {
            let deferred = $q.defer();
            url = _.isNil(certificate.id) ? `/api/${url}/` : `/api/${url}/${certificate.id}/`

            $http.post(url, certificate)
                .then(success, error);

            function success(result) {
                deferred.resolve(result.data);
            }

            function error(data) {
                deferred.reject(data);
            }

            return deferred.promise;
        }

        function saveMedicalCertificate(certificate, isNew) {
            return save("certificate", certificate, isNew);
        }

        function saveModelCertificate(certificate, isNew) {
            return save("model-certificate", certificate, isNew);
        }

        function saveCategory(category) {
            let deferred = $q.defer();
            let url = "/api/certificate-category/" + (_.isNil(category.id) ? "" : category.id + "/")

            $http.post(url, category)
                .then(success, error);

            function success(result) {
                deferred.resolve(result.data);
            }

            function error(data) {
                deferred.reject(data);
            }

            return deferred.promise;
        }

        function listState() {
            $state.go("app.visit.certificate.details-list");
        }


        function newState(model) {
            $state.go("app.visit.certificate.form", {
                certificateId: null,
                model: model,
                copy: null
            }, {inherit: true}).then($state.reload);
        }

        function useState(item) {
            $state.go("app.visit.certificate.form", {
                certificateId: null,
                use: item.id,
                model: true,
                copy: null
            }, {inherit: true}).then($state.reload);
        }

        function editState(item, reload) {
            reload = _.isUndefined(reload) ? true : reload;

            $state.go("app.visit.certificate.form", {
                certificateId: item.id,
                model: item['is_model'],
                copy: null,
                use: null
            }, {inherit: true}).then(reload ? $state.reload : _.noop);
        }

        function duplicateState(item) {
            self.itemDuplicated.next(item.id);
            $state.go("app.visit.certificate.form", {
                certificateId: null,
                model: item.is_model,
                copy: item.id,
                use: null
            }, {inherit: true}).then($state.reload);
        }

        function deleteItem(item) {
            let deferred = $q.defer();

            if (item['is_model']) {
                $http.delete(`/api/model-certificate/${item.id}/`)
                    .then(success, error);
            } else {
                $http.delete(`/api/certificate/${item.id}/`)
                    .then(success, error);
            }

            function success() {
                self.itemDeleted.next(_.cloneDeep(item));
                deferred.resolve(item.id);
            }

            function error(data) {
                deferred.reject(data);
            }

            return deferred.promise;
        }

        function constructedTitle(item) {
            if (_.isUndefined(item))
                return false;
            if (_.isNull(item.title) || _.isEmpty(item.title))
                return $translate.instant("certificate_of") + " " + item.certificate_date;
            return item.title + " (" + item.certificate_date + ")";
        }

        function sign(item) {
            const deferred = $q.defer();
            const urlUsed = `/api/certificate/${item.id}/sign/`;

            $http.put(urlUsed, item)
                .then(result => deferred.resolve(result.data), deferred.reject);

            return deferred.promise;
        }


        function unsign(item, force = false) {
            const deferred = $q.defer();
            const urlUsed = `/api/certificate/${item.id}/unsign/`;

            const reset = () => {
                item.is_locked = true;
                deferred.resolve(item);
            }
            const resp = result => {
                if (result.data) deferred.resolve(result.data)
                else {
                    reset();
                    deferred.reject(null);
                }
            }

            if (force) {
                $http.put(urlUsed, _.pick(item, "id"))
                    .then(result => resp(result), deferred.reject);
            } else {
                $mdDialog
                    .show(_.assign({}, VERIFICATION_DIALOG))
                    .then(pass => {
                        if (pass) {
                            $http.put(urlUsed, _.pick(item, "id"))
                                .then(result => resp(result), deferred.reject);
                        } else reset()
                    }, () => reset());
            }

            return deferred.promise;
        }
    }
})();
