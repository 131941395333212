/**
 * Created by amine on 25/04/2022.
 */
(function () {

    'use strict';

    class ReportsMainCtrl {
        constructor($state, $transitions, $scope) {
            this.state = $state;

            const listener = $transitions.onBefore({}, transition => this.#toList(transition));
            $scope.$on("$destroy", () => listener())
        }

        static get $inject() {
            return ["$state", "$transitions", "$scope"];
        }

        $onInit() {
            if (this.state.is("app.reports")) this.state.go("app.reports.list", {});
        }

        #toList(transition) {
            let state = transition.to();

            if (!_.eq(state.name, "app.reports.list") && _.eq(state.name, "app.reports")) {
                this.state.go("app.reports.list", {});
            }

            return true;
        }

    }

    module.exports = ReportsMainCtrl;

})();
