/**
 * Created by amine on 25/04/2022.
 */
(function () {

    'use strict';

    class ExamListCtrl {
        constructor(examService, $transition$) {
            this.examService = examService;

            this.onReorder = (order) => this.#_onReorder(order)
            this.onPaginate = (page, limit) => this.#_onPaginate(page, limit)

            this.currentPatient = _.get($transition$.params('to'), 'pId', null);
            this.visitContext = !_.isNil(this.currentPatient);

            this.promise = null;
            this.query = {
                patient: this.currentPatient,
                limit: 10,
                page: 1,
                order: "exam_date"
            }
            this.options = [5, 10, 20, 100];
            this.total = 0;
            this.examList = [];
            this.templates = [];
        }

        static get $inject() {
            return ["examService", "$transition$"];
        }

        $onInit() {
            this.examService.sideNaveSubject.next(false);
            this.examService.selectedTemplate.next(false);

            this.examService.getExamTemplates()
                .then(templates => {
                    this.templates = templates;
                    this.getData();
                })
        }

        getData() {
            this.promise = this.examService.getExamListTable(this.query)
                .then(data => {
                    this.total = data.length;
                    this.examList = data.list;
                });
        }

        viewExam(exam) {
            if (this.visitContext) this.examService.viewState(exam);
            else this.examService.goTo(exam, "view")
        }

        editExam(exam) {
            if (this.visitContext) this.examService.editExam(exam);
            else this.examService.goTo(exam, "form")
        }

        duplicateExam(exam) {
            if (this.visitContext) this.examService.duplicateExam(exam);
            else this.examService.goTo(exam, "duplicate")
        }

        removeExam(exam, $event) {
            this.examService.removeExam(exam, this.currentPatient, $event)
                .then(() => this.getData());
        }

        #_onReorder(order) {
            this.query = _.assign(this.query, {order: order});
            this.getData();
        }

        #_onPaginate(page, limit) {
            this.query = _.assign(this.query, {page: page, limit: limit});
            this.getData();
        }

    }

    module.exports = ExamListCtrl;

})();
