(function () {

    'use strict';

    class PeriodontalContainerCtrl {
        constructor(visitService, $transition$, PeriodontalService, $state, $scope) {
            this.state = $state;
            this.visitService = visitService;
            this.periodontalService = PeriodontalService;
            this.scope = $scope;

            this.items = [];
            this.isLocked = true;
            this.currentExam = -1;
            this.newItemSubscription = null;
            this.activeItemSubscription = null;
            this.currentVisitSubscription = null;
            this.currentPatientSubscription = null;

            this.currentPatient = _.get($transition$.params('to'), 'pId');
            this.currentVisit = _.get($transition$.params('to'), 'visitId');
        }

        static get $inject() {
            return ["visitService", "$transition$", "PeriodontalService", "$state", "$scope"];
        }

        $onInit() {
            this.currentVisitSubscription = this.visitService.currentVisitSubject.subscribe(data => this.visit = data);
            this.currentPatientSubscription = this.visitService.currentPatientSubject.subscribe(data => this.patient = data);
            this.activeItemSubscription = this.periodontalService.activeItemSubject.subscribe(data => this.currentExam = data);
            this.newItemSubscription = this.periodontalService.newItemSubject.subscribe(
                data => this.items = _.chain(this.items).pushOrUpdate(data).orderBy('sort_date', 'desc').value()
            );

            this.promise = this.periodontalService.getPeriodontalExams(this.currentPatient)
                .then(data => this.items = data);

            this.scope.$on("$destroy", () => this.onDestroy());
        }

        onDestroy() {
            if (!!this.currentVisitSubscription) this.currentVisitSubscription.unsubscribe();
            if (!!this.currentPatientSubscription) this.currentPatientSubscription.unsubscribe();
            if (!!this.activeItemSubscription) this.activeItemSubscription.unsubscribe();
            if (!!this.newItemSubscription) this.newItemSubscription.unsubscribe();
        }

        viewExam(exam) {
            this.state.go("app.visit.periodontal.detail", {exam}).then(this.state.reload);
        }

        removeExam(exam, ev) {
            this.periodontalService.removeExam(exam, ev)
                .then(() => {
                    this.items = _.without(this.items, exam);
                    if (exam.id == this.currentExam) this.state.go("app.visit.periodontal");
                });
        }
    }

    module.exports = PeriodontalContainerCtrl;

})();
