(function () {

    'use strict';

    class InvoiceBeneficiaryChoiceDialogCtrl {
        constructor($mdDialog) {
            this.dialog = $mdDialog;

        }

        static get $inject() {
            return ["$mdDialog"];
        }

        $onInit() {
            this.value = this.value || 'PATIENT';
        }

        handleChoice() {
            this.dialog.hide(this.value);
        }

        cancel() {
            this.dialog.cancel();
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        controller: InvoiceBeneficiaryChoiceDialogCtrl,
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: false,
        template: require("./invoice-beneficiary-choice.tpl.html"),
    };


})();
