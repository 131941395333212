/**
 * Created by BETALOS on 27/10/2016.
 */
(function () {

    'use strict';

    const {createHttpBackend} = require('../classes/httpBackend');

    module.exports = httpConfig;

    httpConfig.$inject = ["$httpProvider", "$httpBackendProvider"];

    function httpConfig($httpProvider, $httpBackendProvider) {
        $httpProvider.defaults.transformRequest = _.concat(nonNullTransform, $httpProvider.defaults.transformRequest);

        $httpProvider.interceptors.push(deadToken);

        let httpWorker = new Worker(new URL('../workers/httpWorker.js', import.meta.url));

        let originalBackend = $httpBackendProvider.$get[4];
        $httpBackendProvider.$get[4] = function ($browser, $jsonpCallbacks, $document, $xhrFactory) {
            let originalHttpBackend = originalBackend($browser, $jsonpCallbacks, $document, $xhrFactory);
            return createHttpBackend($browser, $xhrFactory, $browser.defer, $jsonpCallbacks, $document[0], httpWorker, originalHttpBackend);
        }

        function nonNullTransform(data) {
            return _.isUndefined(data) || _.has(data, "ignore") ? data : _.omitBy(data, _.isNull);
        }

        deadToken.$inject = ["$q", "$injector"]

        function deadToken($q, $injector) {
            return {
                'responseError': function (response) {
                    if (response.status === 401) {
                        let authService = $injector.get("authService");
                        authService.logout();
                    }

                    return $q.reject(response);
                }
            };
        }
    }

})();
