/**
 * Created by BETALOS on 08/12/2016.
 */

(function () {

    'use strict';

    const TEETH = require('../json/teeth-order.json');
    const MenuCtrl = require('shared/utils/menu-ctrl');
    const toothSurfaceDialog = require('../dialogs/tooth-surface-dialog');
    const toothMenuElem = require('dental-consultation/views/tooth-action-menu.tpl.html');

    class DentalChartCtrl {
        constructor(
            $element, dentalService, dentalUtilsService, $scope, $mdDialog, $mdPanel, $compile, uploadService,
            $mdToast, $translate, $timeout, $auth, moment, patientService
        ) {
            this.$element = $element;
            this.dentalService = dentalService;
            this.dentalUtilsService = dentalUtilsService;
            this.$scope = $scope;
            this.$mdDialog = $mdDialog;
            this.$mdPanel = $mdPanel;
            this.$compile = $compile;
            this.uploadService = uploadService;
            this.patientService = patientService;
            this.$mdToast = $mdToast;
            this.$translate = $translate;
            this.$timeout = $timeout;
            this.$auth = $auth;
            this.moment = moment;

            this.overlay = {};
            this.patient = null;
            this.callbacks = {};
            this.form = "adult";
            this.noPatientImage = true;

            this.isParam = this.$element.is('[param]');
            this.svgContainer = $('.svg-container', this.$element);

            this.selectedTeeth = [];

            this.timer = 0;
            this.delay = 200;
            this.prevent = false;

            this.worker = this.worker || null;

            this.menuCtrl = new MenuCtrl($mdPanel, $scope, $compile);
        }

        static get $inject() {
            return [
                '$element', 'dentalService', 'dentalUtilsService', '$scope', '$mdDialog',
                '$mdPanel', '$compile', 'uploadService', '$mdToast', '$translate', '$timeout',
                '$auth', 'moment', 'patientService'
            ];
        }

        $onInit() {
            this.$scope.$watch('vm.procedure', value => this.procedureChange(value));
            this.$scope.$watch('vm.overlay', value => this.handleOverlay(value), true);

            const isCurrentTooth = this.$element.is('[tooth]');

            if (this.isParam) this.promise = Promise.resolve().then(() => this.getSchema());
            else {
                this.subscription = this.dentalService.panoramics.subscribe(data => this.handlePanoramic(data));
                this.opacitySubscription = this.dentalService.chartOpacity.subscribe(data => this.noOpacity = data);

                this.$element.on('contextmenu', 'g[id]', ev => this.handleContextMenu(ev.currentTarget, ev));
                if (isCurrentTooth) {
                    this.$element.on('click', "g[id]:not([disabled])", (ev) => {
                        this.timer = setTimeout(() => {
                            if (!this.prevent) this.handleClick(ev.currentTarget, ev);
                            this.prevent = false;
                        }, this.delay);
                    });

                    this.$element.on('dblclick', "g[id]", (ev) => {
                        this.prevent = true;
                        clearTimeout(this.timer);
                        this.handleDoubleClick(ev.currentTarget, ev);
                    });
                }
            }
        }

        $onChanges(obj) {
            let patient = _.get(obj, 'patient.currentValue');

            if (patient) {
                this.form = this.getChartForm(patient);

                Promise.resolve().then(() => {
                    this.getSchema();
                    this.filesSubscription = this.uploadService.rootContext('patient', patient.id, 'dental-chart')
                        .subscribe(context => {
                            if (context) this.handleFileContexts(context);
                        });
                });
            }
        }

        $onDestroy() {
            if (this.worker) this.worker.terminate();
            if (this.subscription) this.subscription.unsubscribe();
            if (this.filesSubscription) this.filesSubscription.unsubscribe();
            if (this.opacitySubscription) this.opacitySubscription.unsubscribe();
        }

        procedureChange(procedure) {
            if (procedure) {
                if (procedure['is_multi_teeth']) this.clearCurrentTooth();
                if (procedure['is_all_mouth']) {
                    this.selectedTeeth = TEETH[this.form];
                    this.nextProcedure();
                }
            }
            else this.clearSelectedTeeth();
        }

        handlePanoramic(data) {
            if (this.patient && this.patient.id !== data.patient_id) return false;

            else {
                this.panoramics = data;

                if (data.previews.length > 0) {
                    const previewBaseUrl = _.chain(data).get('previews').orderBy('sort_date', 'desc').get('0.url').value();
                    this.panoramicPreview = `${previewBaseUrl}&auth=${this.$auth.getToken()}`;
                }
                else this.panoramicPreview = false;
            }
        }

        selectSchema(schema) {
            this.form = schema;
            this.getSchema();
        }

        getSchema() {
            this.promise = this.dentalUtilsService.getSchemaSvg(this.form)
                .then(svg => {
                    this.svgContainer.html(svg);

                    if (!this.isParam) {
                        this.$timeout(() => this.handleOverlay(this.overlay), 100, false);
                        this.$timeout(() => this.context ? this.handleFileContexts(this.context) : null, 50, false);
                    }
                });
        }

        removeProcedure() {
            this.procedure = null;
        }

        nextProcedure() {
            const teeth = _.uniq(this.selectedTeeth);
            const teethProcedure = _.assign(
                {
                    discount: 0,
                    progress: 0,
                    teeth: teeth,
                    dental_procedure: this.procedure,
                    teeth_str: _.get(this.procedure, 'is_all_mouth') ? '-' : teeth.join(', '),
                    price: _.get(this.procedure, 'procedure_price') * (this.procedure.is_unit_price ? teeth.length : 1),
                },
                _.pick(this.procedure, ['code', 'name'])
            );

            this.dentalService.chartSubject.next(teethProcedure);
            this.removeProcedure();
            this.clearSelectedTeeth();
        }

        clearSelectedTeeth() {
            this.selectedTeeth = [];
            $("g[id]", this.$element).removeClass('selected');
        }

        // chart files
        handleFileContexts(context) {
            this.context = context;

            const filesClass = _.reduce(
                context.teeth, (sum, value, key) => `${sum ? sum + ',' : ''} #${key} .number > text`, false
            );

            $('.number > text', this.$element).removeClass('has-files');
            $(filesClass, this.$element).addClass('has-files');
        }

        // images handle
        viewPanoramics(ev) {
            const previews = _.orderBy(this.panoramics.previews, 'sort_date', 'desc');
            this.dentalUtilsService.visualisePanoramics(previews, 0, ev, {allowEdit: false});
        }

        // tooth menu methods
        toothFiles(tooth, event) {
            this.closePanel();

            this.promise = this.uploadService.rootContextFiles('patient', this.patient.id, {teeth: _.castArray(tooth)})
                .then(data => this.filesSuccess(data, event), () => this.filesError());
        }

        filesSuccess(items, event) {
            if (items.length === 0) this.filesError();
            else {
                const files = _.filter(items, 'is_converted');
                this.uploadService.visualizeFiles(files, 0, event, false, false);
            }
        }

        filesError(tooth) {
            let simpleToast = this.$mdToast.simple()
                .textContent(this.$translate['instant']('dental_setup_no_files_found', {tooth}))
                .position("bottom left")
                .hideDelay(1500);

            this.$mdToast.show(simpleToast);
        }

        toothHistoric(tooth, event) {
            this.closePanel();
            this.callbacks.toothHistoric(tooth, event);
        }

        associateFiles(tooth, event) {
            this.closePanel();

            const context = {teeth: _.castArray(tooth)};
            this.callbacks.uploadFiles(event, context);
        }

        toothShowFiles(tooth) {
            this.closePanel();

            const context = {teeth: _.castArray(tooth)};
            this.callbacks.showContextFiles(context);
        }

        handleMenu(toothNumber) {
            return _.replace(toothMenuElem, /toothId/g, toothNumber);
        }

        handleContextMenu(elem, ev) {
            ev.preventDefault();

            const toothNumber = $(elem).attr('id');

            const menuElem = this.handleMenu(toothNumber);
            this.menuCtrl.open(ev, menuElem).then(ref => this.panelRef = ref);
        }

        handleClick(elem, ev) {
            const toothNumber = $(elem).attr('id');

            if (_.isNil(this.procedure)) return false;

            else if (this.procedure['is_multi_teeth']) this.handleSelectTeeth(toothNumber);

            else if (this.procedure['has_surface']) {
                const dialog = _.assign({}, toothSurfaceDialog, {
                    targetEvent: ev,
                    locals: {
                        tooth: toothNumber,
                        charting: _.chain(this.procedure.charting).find(['is_surface', true]).cloneDeep().value()
                    }
                });

                this.$mdDialog.show(dialog)
                    .then(data => this.clickSuccess(toothNumber, data));
            }

            else this.clickSuccess(toothNumber);
        }

        clickSuccess(toothNumber, charting) {
            const teeth = _.castArray(toothNumber);
            const toothProcedure = _.assign(
                {
                    teeth,
                    progress: 0,
                    discount: 0,
                    teeth_str: teeth.join(', '),
                    dental_procedure: this.procedure,
                    price: _.get(this.procedure, 'procedure_price'),
                },
                _.pick(this.procedure, ['code', 'name']),
                charting ? {charting} : {}
            );

            this.dentalService.chartSubject.next(toothProcedure);
        }

        handleSelectTeeth(toothNumber) {
            if (_.includes(this.selectedTeeth, toothNumber)) _.pull(this.selectedTeeth, toothNumber);
            else if (this.procedure['is_select_between_teeth'] && this.selectedTeeth.length > 0) this.selectedTeeth = this.valuesBetween(toothNumber);
            else this.selectedTeeth.push(toothNumber);

            $("g[id]", this.$element).removeClass('selected');

            const $selector = _.map(this.selectedTeeth, i => `#${i}`).join(' , ');
            $($selector, this.$element).addClass('selected');

            this.$scope.$applyAsync();
        }

        // select current tooth
        handleDoubleClick(element) {
            if (this.procedure && this.procedure['is_multi_teeth']) return false;

            const $currentTooth = $(element);
            const currentTooth = parseInt($currentTooth.attr('id'));

            if (currentTooth === this.currentTooth) this.clearCurrentTooth();
            else {
                this.currentTooth = currentTooth;
                $currentTooth.addClass('active').removeAttr('disabled');
                $("g[id]", this.$element).not(element).removeClass('active').attr('disabled', 'disabled');
            }

            this.$scope.$applyAsync();
        }

        clearCurrentTooth() {
            this.currentTooth = null;
            $("g[id]", this.$element).removeClass('active').removeAttr('disabled');
        }

        // handle Overlay
        handleOverlay(overlay) {
            $('.zones.ordered', this.$element).each((ev) => {
                const zone = $(ev.currentTarget);
                const children = _.orderBy(zone.children(), this.handleItemOrder);

                zone.html(children);
                zone.removeClass('ordered');
            });

            if (_.isEmpty(overlay)) return false;
            else if (_.isArray(overlay)) $('.zones', this.$element).each((ev, element) => {
                const zone = $(element);
                this.orderZone(zone, overlay);
            });

            else if (_.isPlainObject(overlay)) _.forEach(overlay, (value, key) => {
                if (_.isEmpty(value)) return false;

                const selector = `#${key} .zones`;
                const zone = $(selector);

                this.orderZone(zone, value);
            });
        }

        orderZone(parent, overlay) {
            let children = parent.children();

            const childrenCount = children.length;
            const orderedChildren = _.orderBy(children, handleOverlayOrder);

            parent.html(orderedChildren);
            parent.addClass('ordered');

            function handleOverlayOrder(item) {
                const $item = $(item);
                const zoneName = $item.attr('class');
                const zoneOrder = findZone(zoneName);

                return zoneOrder ? (childrenCount / zoneOrder) + childrenCount : $item.data('index');
            }

            function findZone(zone) {
                // TODO to be fixed for multi zone names (2.1) NOT REVIEWED
                return _.chain(overlay).find(zone).get(zone).value();
            }
        }

        handleItemOrder(item) {
            return $(item).data('index');
        }

        valuesBetween(from) {
            let teeth = TEETH[this.form];
            let fromIndex = _.indexOf(teeth, parseInt(from));
            let toIndex = _.indexOf(teeth, _.chain(this.selectedTeeth).last().parseInt().value());
            let teethBetween = _.chain(teeth).slice(
                Math.min(toIndex, fromIndex), Math.max(toIndex, fromIndex) + 1
            ).invokeMap('toString').value();

            return _.chain(this.selectedTeeth).concat(teethBetween).compact().value();
        }

        closePanel() {
            this.panelRef && this.panelRef.hide();
        }

        getChartForm(patient) {
            if (patient['age_decimal'] < 6)
                return 'child';

            if (patient['age_decimal'] >= 6 && patient['age_decimal'] < 7.5)
                return '6-years';

            if (patient['age_decimal'] >= 7.5 && patient['age_decimal'] < 12)
                return '7-and-half';

            if (patient['age_decimal'] >= 12 && patient['age_decimal'] < 13.5)
                return '12-years';

            if (patient['age_decimal'] >= 13.5 && patient['age_decimal'] < 18)
                return '13-and-half';

            return "adult";
        }
    }

    module.exports = {
        bindings: {
            callbacks: "<?",
            style: '<mnStyle',
            patient: '<patient',
            overlay: '<mnOverlay',
            currentTooth: "=?tooth",
            procedure: "=?chosenProcedure",
        },
        controllerAs: "vm",
        controller: DentalChartCtrl,
        template: require('dental-consultation/views/dental-chart.tpl.html'),
    };

})();
