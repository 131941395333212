/**
 * Created by Amine on 28/01/2017.
 */

(function () {
    'use strict';

    const {BehaviorSubject, take, skipWhile} = require("rxjs");
    const SHARING_DIALOG = require('shared/dialogs/email-sharing-dialog');

    module.exports = {
        controller: mnPrintCtrl,
        controllerAs: "vm",
        bindings: {
            disabled: "=mnDisabled",
            group: "@",
            view: "@",
            patient: "<",
            model: "=",
            prePrint: "&?",
            extraQuery: "<?",
        },
        template: tpl,
    };

    mnPrintCtrl.$inject = ["printService", "$element", "$scope", "$mdDialog", "$translate", "externalConnectionService"];

    function mnPrintCtrl(printService, $element, $scope, $mdDialog, $translate, externalConnectionService) {
        let vm = this;

        let gotModel = null;
        let $unwatch = null;

        vm.$onInit = init;

        vm.printItem = printItem;
        vm.printItemToPdf = printItemToPdf;
        vm.shareAsMail = shareAsMail;

        function init() {
            vm.view = vm.view || null;
            vm.extraQuery = vm.extraQuery || {};
            gotModel = new BehaviorSubject(vm.model);

            $element.addClass("layout-row");
            $element.is('[mn-disabled]') ? $scope.$watch("vm.disabled", handleDisabled) : _.noop();

            vm.noShare = !!$element.is('[no-share]');

            $unwatch = $scope.$watch("vm.model", function () {
                gotModel.next(vm.model);
            });

            if (!_.isNil(vm.group) && !_.isEmpty(vm.group)) {
                printService
                    .getGroupByName(vm.group)
                    .then(success, _.noop);
            }

            function success(data) {
                vm.templates = _.filter(data.templates, o => !o.is_hidden);
            }

            vm.$onDestroy = () => {
                $unwatch();
                gotModel.complete();
            }
        }

        function prePrintItem(uid, print) {
            if (_.isNil(vm.group)) return false;
            const args = _.slice(arguments, 2);
            const qs = _.qs(vm.extraQuery);

            if (_.isUndefined(vm.prePrint)) {
                print(vm.group, vm.model, uid, qs, vm.view, ...args);
            } else {
                vm.prePrint().then(success, _.noop);
            }

            function success() {
                gotModel
                    .pipe(
                        skipWhile(model => !model),
                        take(1)
                    )
                    .subscribe({
                        next: model => print(vm.group, model, uid, qs, vm.view, ...args),
                        onerror: () => _.noop()
                    });
            }
        }

        function printItem(uid) {
            prePrintItem(uid, printService.printItem);
        }

        function printItemToPdf(uid) {
            let titleKey = _.snakeCase(`pdf_title_${vm.group}`);
            let title = $translate.instant(titleKey);

            prePrintItem(uid, printService.printItemToPdf, title);
        }

        function shareAsMail(event) {
            let titleKey = _.snakeCase(`pdf_title_${vm.group}`);
            let title = $translate.instant(titleKey);

            $mdDialog.show(_.assign({}, SHARING_DIALOG, {
                targetEvent: event,
                locals: {
                    patient: vm.patient,
                    templates: vm.templates,
                    title: title
                }
            })).then(data => {
                prePrintItem(data.uid, (group, model, uid, extraQuery, viewSet, message) => {
                    externalConnectionService.shareDocument(group, model, uid, extraQuery, message, title, viewSet)
                }, data.message)
            }, _.noop);
        }

        function handleDisabled(value) {
            if (value) {
                $('.md-button', $element).attr('disabled', 'disabled')
            } else {
                $('.md-button', $element).removeAttr('disabled')
            }
        }
    }

    tpl.$inject = ["$element", "$attrs"];

    function tpl(element, attr) {
        let $tpl = $('<div />').html(
            require('shared/views/print.tpl.html')
        );

        if (element.is('[button-class]')) $('md-button', $tpl)
            .not('[aria-label="print-menu"]')
            .removeClass()
            .addClass(attr['buttonClass']);

        return $tpl.html();
    }


})();
