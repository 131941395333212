/**
 * Created by amine on 26/03/2017.
 */
(function () {
    'use strict';

    module.exports = printService;

    const FRAME_ID = "mn-print-frame-id";
    const download = require('downloadjs');

    printService.$inject = ["$auth", "$timeout", "$http", "$q", "$mdToast"];

    function printService($auth, $timeout, $http, $q, $mdToast) {
        let self = this;

        self.staff = null;
        self.staffDefaults = null;

        self.printItem = printItem;
        self.printItemToPdf = printItemToPdf;
        self.customPrint = customPrint;

        self.printBlob = printBlob;
        self.showPreview = showPreview;

        self.getGroups = getGroups;
        self.getGroupByName = getGroupByName;
        self.getTemplate = getTemplate;
        self.hideGroup = hideGroup;
        self.saveTemplate = saveTemplate;
        self.duplicateTemplate = duplicateTemplate;
        self.removeTemplate = removeTemplate;

        self.getDefaults = getDefaults;

        self.saveDefaults = saveDefaults;
        self.saveImages = saveImages;

        function prePrintItem(viewName, group, model, uid, extraQuery, viewSet) {
            if (_.isNil(viewSet)) viewSet = group;

            let extra = '';
            const query = _.isEmpty(extraQuery) ? "" : `&${extraQuery}`;

            if (!_.isNil(uid)) extra = `&uid=${uid}`;
            else if (!_.isNil(self.staffDefaults)) {
                let defaultTmp = _.chain(self.staffDefaults.defaults).values().find({
                    group: {name: group}
                }).get("template_uid").value();
                extra += _.isUndefined(defaultTmp) ? "" : `&uid=${defaultTmp}`;
            }

            return `/api/${viewSet}/${model}/${viewName}/?group=${group}${extra}${query}`;
        }

        function printItem(group, model, uid, extraQuery, viewSet) {
            let url = prePrintItem('print', group, model, uid, extraQuery, viewSet);
            showPreview(url);
        }

        function printItemToPdf(group, model, uid, extraQuery, viewSet, title) {
            let url = prePrintItem('pdf', group, model, uid, extraQuery, viewSet);

            let promise = $http.get(url, {responseType: 'arraybuffer'})
                .then(result => download(result.data, `${!!title ? title : `[${group}]`}.pdf`));

            let toast = getLoadingToast(promise);
            $mdToast.show(toast);
        }

        function customPrint(query, group, uid, viewSet, view) {
            if (_.isNil(viewSet)) viewSet = group;
            if (_.isNil(view)) view = "print";

            showPreview(
                `/api/${viewSet}/${view}/?group=${group}${uid ? "&uid=" + uid : ''}&${_.qs(query)}`
            );
        }

        function showPreview(url, title = "unnamed") {
            let deferred = $q.defer();

            let toast = getLoadingToast(deferred.promise);
            $mdToast.show(toast);

            $http.get(url, {responseType: 'blob'})
                .then(response => {
                    printBlob(response.data)
                        .then(() => deferred.resolve(true), () => {
                            $http.get(url, {responseType: 'arraybuffer'})
                                .then(result => {
                                    download(result.data, `${title}.pdf`)
                                    deferred.resolve(true);
                                }, () => deferred.reject(true));
                        });
                    //console.log(window.URL.createObjectURL(response.data))
                }, error => {
                    deferred.reject(error);
                });

            return deferred.promise;
        }

        function printBlob(blob) {
            let deferred = $q.defer();
            const objectUrl = window.URL.createObjectURL(blob);
            const $iframe = getFrame().attr("src", objectUrl);

            const infanticide = () => {
                $iframe.remove();
                clearTimeout(iframeError);
                window.removeEventListener('focus', infanticide);
            };
            const onError = () => {
                deferred.reject(false);
            }

            const iframeError = setTimeout(() => onError(), 5000);

            $iframe.on("load", ev => {
                ev.target.contentWindow.print();
                deferred.resolve(true);
                window.addEventListener('focus', infanticide);
            });

            return deferred.promise;
        }

        function getGroups() {
            let deferred = $q.defer();
            let url = "/api/print/";

            $http.get(url)
                .then(success, error);

            function success(result) {
                deferred.resolve(result.data);
            }

            function error(data) {
                deferred.reject(data);
            }

            return deferred.promise;
        }

        function getGroupByName(group) {
            let deferred = $q.defer();
            let url = `/api/print/${group}/get-group/`;

            $http.get(url)
                .then(success, error);

            function success(result) {
                deferred.resolve(result.data);
            }

            function error(data) {
                deferred.reject(data);
            }

            return deferred.promise;
        }

        function getTemplate(uid) {
            let deferred = $q.defer();
            let url = `/api/print/${uid}/template/`;

            $http.get(url)
                .then(success, error);

            function success(result) {
                deferred.resolve(result.data);
            }

            function error(data) {
                deferred.reject(data);
            }

            return deferred.promise;
        }

        function hideGroup(group, hidden) {
            let deferred = $q.defer();
            let url = `/api/print/${group.id ? group.id : group}/`;

            $http.put(url, {hidden})
                .then(success, error);

            function success(result) {
                deferred.resolve(result.data);
            }

            function error(data) {
                deferred.reject(data);
            }

            return deferred.promise;
        }

        function saveTemplate(template) {
            let deferred = $q.defer();
            let url = `/api/print/${template.uid}/save-template/`;

            $http.post(url, template)
                .then(success, error);

            function success(result) {
                deferred.resolve(result.data);
            }

            function error(data) {
                deferred.reject(data);
            }

            return deferred.promise;
        }

        function duplicateTemplate(template) {
            let deferred = $q.defer();
            let url = `/api/print/${template.uid}/duplicate-template/`;

            $http.get(url, template)
                .then(success, error);

            function success(result) {
                deferred.resolve(result.data);
            }

            function error(data) {
                deferred.reject(data);
            }

            return deferred.promise;
        }

        function removeTemplate(template) {
            let url = `/api/print/${template.uid}/delete-template/`;
            return $http.delete(url, template);
        }

        function getDefaults(staff) {
            let deferred = $q.defer();

            if (_.has(staff, "id")) {
                let url = `/api/print/${staff.id}/get-defaults/`;
                $http.get(url).then(success, error);
            } else success({data: null});

            function success(result) {
                self.staff = staff;
                self.staffDefaults = result.data;
                deferred.resolve(result.data);
            }

            function error(data) {
                deferred.reject(data);
            }

            return deferred.promise;
        }

        function saveDefaults(group, template) {
            let deferred = $q.defer();
            if (self.staff) {
                self.staffDefaults.defaults[group.id] = {group, template_uid: template};

                let url = `/api/print/${self.staff.id}/save-defaults/`;
                $http.post(url, self.staffDefaults).then(success, error);
            } else $timeout(() => deferred.reject(null), 100);

            function success(result) {
                deferred.resolve(result.data);
            }

            function error(data) {
                deferred.reject(data);
            }

            return deferred.promise;
        }

        function saveImages(files) {
            let deferred = $q.defer();
            let url = "/api/print/save-images/";

            $http.post(url, {files: files})
                .then(success, error);

            function success(result) {
                deferred.resolve(result.data);
            }

            function error(data) {
                deferred.reject(data);
            }

            return deferred.promise;
        }

        // loading
        function getLoadingToast(promise) {
            return $mdToast.mnAdvancedToast()
                .handle(promise)
                .label('print_loading_progress');
        }

        function getFrame() {
            let $frame = $(`#${FRAME_ID}`, document.body);

            if ($frame.length > 0) $frame.remove();

            $frame = $("<iframe />").attr('id', FRAME_ID);
            $(document.body).append($frame);

            return $frame;
        }
    }

})();
