(function () {
    'use strict';

    class MobileConsultationCtrl {
        constructor(consultationService, $transition$, $scope) {
            this.service = consultationService;

            this.currentConsultation = _.get($transition$.params('to'), 'consultation');
            const lockSubscription = this.service.lockSubject.subscribe(data => {
                this.isLocked = data.lock;
                $scope.$applyAsync();
            });

            this.handleChanges = _.mnDelay(() => this.changes(), 500);
            $scope.$on("$destroy", () => lockSubscription.unsubscribe());
        }

        static get $inject() {
            return ['consultationService', '$transition$', '$scope'];
        }

        $onInit() {
            this.promise = this.service.getMobileConsultationDetail(this.currentConsultation)
                .then(data => {
                    this.consultation = data;


                    this.service.currentSubscription.next({
                        currentActiveConsultId: -1,
                        currentEditConsult: [this.currentConsultation, true],
                        currentConsultationDate: _.get(this.consultation, "consultation_date")
                    });
                })
        }

        lock() {
            this.service.lockSubject.next({lock: !this.isLocked});
        }

        changes() {
            this.service.updateMobileConsultation(this.consultation)
                .then(data => null);
        }
    }

    module.exports = MobileConsultationCtrl;

})();